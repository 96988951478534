import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import Action from '../atoms/Action';
import FilePicker from '../atoms/FilePicker';
import { COLOR_BLUE, COLOR_DARK_BLUE, COLOR_TEXT, COLOR_WHITE } from '../styles/colors';
import Toast, { ToastType } from '../atoms/Toast';
import MEDIA from '../styles/media';
import ImportDepthChartActivityTable from '../organisms/ImportDepthChartActivityTable';
import ImportDepthChartTeamTable from '../organisms/ImportDepthChartTeamTable';
import { uploadAndPostDepthChartXLS } from '../services/export-xls-report';
import gql from '../services/gql';
import OurladsFileUploads, { ourladsFileUploadAuditLogs } from '../types/OurladsFileUploads';
import Loader from '../atoms/Loader';
import * as clientStorage from '../services/client-storage';
import { StorageKey } from '../services/client-storage';

const useStyles = makeStyles(theme => ({
  importPageTemplate: {
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: "right",
    padding: theme.spacing(3, 3, 0),
    position: 'relative',
  },
  headerTitle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    ...theme.typography.h2,
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    width: '118px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1.5),
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    fontSize: theme.typography.pxToRem(14),

    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
      color: COLOR_WHITE,
      textDecoration: 'none',
    }
  },
  filePicker: {
    backgroundColor: COLOR_BLUE,
    borderRadius: '5px',
    color: COLOR_WHITE,
    cursor: 'pointer',
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),

    '&:hover': {
      backgroundColor: COLOR_DARK_BLUE,
    }
  },
  fileInputLabelClass: {
    height: '100%',
    width: '100%',
    lineHeight: '50px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  gridContainerColumn: {
    padding: '0 10px',
  },
  '@media (max-width: 1800px)': {
    gridContainer: {
      width: 'calc(100% + 20px)',
      margin: '0 -10px',
    },
    gridContainerColumn: {
      padding: '0 10px',
    },
  },

  [MEDIA.MOBILE_AND_TABLET]: {
    gridContainer: {
      flexDirection: 'column',
    },
    gridContainerColumn: {
      flexBasis: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(5),

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },
  wrapContainer: {
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(3, 3),
  },
}));

export default function ImportDepthChartPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAuditLogs, setLoadingAuditLogs] = useState<boolean>(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastType, setToastType] = useState<ToastType>(ToastType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [ourladsFileUpload, setOurladsFileUpload] = useState<OurladsFileUploads[]>([]);
  const [ourladsFileUploadAuditLogs, setOurladsFileUploadAuditLogs] = useState<ourladsFileUploadAuditLogs[]>([]);
  const [fileNameAndDate, setFileNameAndDate] = useState({
    id: 0,
    fileName: '',
    createdAt: ''
  });
  const [currentActivity, setCurrentActivity] = useState<number>((clientStorage.get(StorageKey.IMPORT_DEPTH_CHART_ACTIVITY_TABLE) || {}).currentActivity || 1);
  const [currentAuditsLog, setCurrentAuditsLog] = useState<number>((clientStorage.get(StorageKey.IMPORT_DEPTH_CHART_AUDIT_LOG) || {}).currentAuditsLog || 1);
  const [limit, setLimit] = useState<number>((clientStorage.get(StorageKey.IMPORT_DEPTH_CHART) || {}).limit || 15);
  const [prevLimit, setPrevLimit] = useState<number>((clientStorage.get(StorageKey.IMPORT_DEPTH_CHART) || {}).limit || 15);

  useEffect(() => {
    fetchFileUploads();
  }, []);

  function showToast(message: string, type: ToastType = ToastType.SUCCESS) {
    setToastMessage(message);
    setToastType(type);
    setToastVisible(true);
  }

  function handleRowClick(data: any) {
    fetchAuditLogs(data);
  }

  // import and upload file
  function onFileSelect(file: any) {
    setOurladsFileUploadAuditLogs([]);
    if (file) {
      setLoading(true);

        uploadAndPostDepthChartXLS(file)
          .then((success:boolean) => {
            if (success) {
              showToast('Uploaded', ToastType.SUCCESS);
              fetchFileUploads();
            } else {
              showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
            }
          })
          .catch((error) => {
            console.error(error);
            showToast('Oops, something is wrong. Try again or contact our support team.', ToastType.ERROR);
          })
          .finally(() => setLoading(false));
    }
  }


  //ourlads file upload Audit logs on file select
  function fetchAuditLogs(data: any) {
    const id = data && data.id;
    setFileNameAndDate({
      id: data && data.id,
      fileName: data && data.fileName,
      createdAt: data && data.createdAt
    });
    setLoadingAuditLogs(true);
    gql(`
      ourladsFileUploadAuditLogs (ourladsFileUploadId: ${id}) {
        id,
        ourladsFileUploadId,
        teamName,
        odk,
        type,
        message,
        count
      }
    `)
      .then((data: any) => data.ourladsFileUploadAuditLogs as ourladsFileUploadAuditLogs[])
      .then((ourladsFileUploadsAuditLog: ourladsFileUploadAuditLogs[]) => {
        setOurladsFileUploadAuditLogs(ourladsFileUploadsAuditLog);
        setLoadingAuditLogs(false);
      })
      .catch(() => {
        setLoadingAuditLogs(false);
        showToast("Failed to fetch file's data.", ToastType.ERROR);
      })
  }

  // fetch all files
  function fetchFileUploads () {
    setLoading(true);
    setLoadingAuditLogs(true);
    gql(`
      ourladsFileUploads {
        id,
        fileName,
        fileUrl,
        status,
        createdAt
      }
    `)
      .then((data: any) => data.ourladsFileUploads as OurladsFileUploads[]
      )
      .then((ourladsFileUploads: OurladsFileUploads[]) => {
        setOurladsFileUpload(ourladsFileUploads);
        if (ourladsFileUploads && ourladsFileUploads.length && ourladsFileUploads[0] && ourladsFileUploads[0].id) {
          fetchAuditLogs(ourladsFileUploads[0]);
          setLoadingAuditLogs(true);
        }
        setLoading(false)
        setRefresh(false);
      })
      .catch(() => {
        setLoading(false);
        showToast('Failed to fetch files.', ToastType.ERROR);
      })
  }

  useEffect(() => {
    if (refresh) {
      fetchFileUploads();
    }
  }, [refresh]);

  useEffect(() => {
    clientStorage.save(StorageKey.IMPORT_DEPTH_CHART_ACTIVITY_TABLE, {limit, currentActivity});
  }, [limit, currentActivity]);

  useEffect(() => {
    if (prevLimit !== limit) {
      setCurrentActivity(1);
      setCurrentAuditsLog(1);
      setPrevLimit(limit);
    }
  }, [limit]);

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <div className={classes.actions}>
            <FilePicker
              className={clsx(classes.action, classes.filePicker)}
              fileInputLabelClass={classes.fileInputLabelClass}
              name='xls-import'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={(file: any) => onFileSelect(file)}
            >
              Import
            </FilePicker>


            <Action
              className={classes.action}
                onClick={() => {
                  setRefresh(true);
                }}
              icon={RefreshIcon}
              iconClassName={classes.icon}
            >
              Refresh
            </Action>
          </div>
        </div>
        <div className={classes.wrapContainer}>
          {loading ?
            <Loader inProgress={loading} />
          : 
          <Grid
            className={classes.gridContainer}
            container
          >
            <Grid
              className={classes.gridContainerColumn}
              item
              lg={5}
              sm={6}
            >
              <ImportDepthChartActivityTable 
                ourladsFileUploads={ourladsFileUpload}
                handleRowClick={handleRowClick}
                fileNameAndDate={fileNameAndDate}
                currentActivity={currentActivity}
                setCurrentActivity={setCurrentActivity}
                limit={limit}
              />
            </Grid>

            <Grid
              className={classes.gridContainerColumn}
              item
              lg={7}
              sm={6}
            >
              <ImportDepthChartTeamTable 
                ourladsFileUploadAuditLogs={ourladsFileUploadAuditLogs}
                loadingAuditLogs={loadingAuditLogs}
                fileNameAndDate={fileNameAndDate}
                setCurrentAuditsLog={setCurrentAuditsLog}
                currentAuditsLog={currentAuditsLog}
                limit={limit}
              />
            </Grid>
          </Grid>
           }
        </div>

      </Paper>
      <Toast
        visible={toastVisible}
        type={toastType}
        onHide={() => setToastVisible(false)}
      >
        {toastMessage}
      </Toast>
    </>
  )
}
