import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveIcon from '../icons/RemoveIcon';
import TeamLogo from '../atoms/TeamLogo';
import {
  COLOR_BORDER,
  COLOR_DARK_GRAY,
  COLOR_SHADOW,
  COLOR_TEXT,
  COLOR_WHITE,
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import Team from '../types/Team';
import MEDIA from '../styles/media';

interface TeamAutoCompleteProps {
  className?: string;
  itemClassName?: string;
  inputClassName?: string;
  label?: string;
  nullable?: boolean;
  loading?: boolean;
  teams: Array<Team>;
  selectedTeam?: Team|null;
  onSelect: (team:Team|null) => void;
  onBlur?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamAutoComplete: {
    boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
    background: COLOR_WHITE,
    border: `1px solid ${COLOR_BORDER}`,
    position: 'relative',

    '&:before': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '20px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(-45deg)',
    },
    '&:after': {
      content: '""',
      width: '2px',
      height: '10px',
      background: COLOR_TEXT,
      position: 'absolute',
      top: '50%',
      right: '14px',
      zIndex: '10',
      transform: 'translateY(-50%) rotate(45deg)',
    },

    '&:not($open)': {
      '& $input > input': {
        opacity: 0,
      },
    },
  },
  open: {
    '& $label': {
      display: 'none',
    },
  },

  endAdornment: {
    display: 'none',
  },

  input: {
    marginTop: '0 !important',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },

    '& > input': {
      padding: theme.spacing(2, 4, 2, 2),
    },
  },
  label: {
    top: '50%',
    left: theme.spacing(2),
    transform: 'translateY(-50%)',
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    color: COLOR_TEXT,
  },
  selectedTeamLabel: {},

  teamItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    fontFamily: FONT_PROXIMA_NOVA,
  },
  teamLogo: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
  },

  clearButton: {
    width: 208,
    appearance: 'none',
    padding: 0,
    margin: 0,
    background: 'rgba(255, 255, 255, 0)',
    border: `1px solid ${COLOR_WHITE}`,
    fontFamily: FONT_PROXIMA_NOVA,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    flexGrow: 1,
    color: COLOR_TEXT,
    cursor: 'pointer',
    transition: 'background 0.2s, border-radius 0.4s',
    boxSizing: 'border-box',

    '&:hover': {
      background: 'rgba(255, 255, 255, 1)',
      borderColor: COLOR_BORDER,
      boxShadow: `0 10px 10px 0 ${COLOR_SHADOW}`,
      borderRadius: 4,

      '& $clearIcon': {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(5),
        transform: 'scale(1.2)',
      },
    },
  },
  clearButtonContent: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
  },
  clearIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(2),
    color: COLOR_DARK_GRAY,
    transform: 'scale(1)',
    transition: 'margin 0.3s, transform 0.3s, color 0.3s',
  },

  [MEDIA.DESKTOP]: {
    teamLogo: {
      marginRight: theme.spacing(2),
    },
  },
}), { name: TeamAutoComplete.name });

const LIMIT = 5;

export default function TeamAutoComplete (props: TeamAutoCompleteProps) {
  const {
    className = '',
    itemClassName = '',
    inputClassName = '',
    teams,
    label = 'Select a Team',
    nullable = false,
    loading = false,
    selectedTeam,
    onSelect,
    onBlur,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [matchingTeams, setMatchingTeams] = useState<Array<Team|null>>(teams);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    const fullyMatchedTeam:Team|undefined = teams
      .find((team:Team) => team.name.toLowerCase() === searchValue.toLowerCase());
    const otherMatchingTeams:Array<Team|null> = teams
      .filter((team:Team) => {
        return team.name.toLowerCase().includes(searchValue.toLowerCase())
          && team.name.toLowerCase() !== searchValue.toLowerCase();
      })
      .slice(0, fullyMatchedTeam ? LIMIT - 1 : LIMIT);
    const allMatchedTeams = fullyMatchedTeam
      ? [fullyMatchedTeam, ...otherMatchingTeams]
      : otherMatchingTeams;

    if (nullable) {
      allMatchedTeams.unshift(null);
    }

    setMatchingTeams(allMatchedTeams);
  }, [teams, searchValue, open]);

  function onChange (team:Team|null) {
    onSelect(team);
    setSearchValue('');
  }

  return (
    <Autocomplete
      className={clsx(
        classes.teamAutoComplete,
        open && classes.open,
        className,
      )}
      classes={{
        endAdornment: classes.endAdornment,
        input: inputClassName,
      }}
      loading={loading}
      options={matchingTeams}
      getOptionLabel={(team:Team|null) => {
        if (team === null && nullable) {
          return 'None';
        }

        return selectedTeam
          ? (team?.id === selectedTeam.id ? '' : searchValue)
          : team?.name || '';
      }}
      renderOption={(team:Team|null) => (
        <div className={clsx(classes.teamItem, itemClassName)}>
          {(nullable && team === null)
            ? <button
                className={classes.clearButton}
                onClick={() => onChange(null)}
              >
                <div className={classes.clearButtonContent}>
                  <RemoveIcon className={classes.clearIcon} />
                  Clear
                </div>
              </button>
            : <>
                <TeamLogo
                  key={team?.name}
                  className={classes.teamLogo}
                  team={team || undefined}
                />
                {team?.name}
              </>
          }
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ className: clsx(classes.label, selectedTeam && classes.selectedTeamLabel) }}
          InputProps={{ ...params.InputProps, className: classes.input }}
          label={selectedTeam
            ? (
              <div className={clsx(classes.teamItem, itemClassName)}>
                <TeamLogo
                  key={selectedTeam.name}
                  className={classes.teamLogo}
                  team={selectedTeam}
                />
                {selectedTeam.name}
              </div>
            )
            : label
          }
          variant='standard'
          onChange={(event:any) => setSearchValue(event.target.value)}
        />
      )}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event:any, team:Team|null) => onChange(team)}
      onBlur={onBlur}
    />
  );
}
