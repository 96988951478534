import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PageContainer from './PageContainer';
import TeamsIcon from '../icons/TeamsIcon';
import { COLOR_TEXT } from '../styles/colors';
import Toggle from '../atoms/Toggle';
import TeamAutoComplete from '../molecules/TeamAutoComplete';
import ConferencesList from '../organisms/ConferencesList';
import gql from '../services/gql';
import { State } from '../redux/reducers';
import { fetchCollegeTeams, fetchNFLTeams } from '../redux/dispatchers/teams';
import { fetchFBSConferences, fetchFCSConferences } from '../redux/dispatchers/conferences';
import MEDIA from '../styles/media';
import Team from '../types/Team';
import Conference from '../types/Conference';
import User from '../types/User';

interface TeamsPageTemplateProps {
  user?: User;
  collegeTeams: Team[];
  collegeTeamsLoading: boolean;
  nflTeams: Team[];
  nflTeamsLoading: boolean;
  nflAccess: boolean;
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
  fetchCollegeTeams: () => void;
  fetchNFLTeams: () => void;
  fetchFBSConferences: () => void;
  fetchFCSConferences: () => void;
}

const CATEGORY = {
  COLLEGE: 'college',
  NFL: 'nfl',
};

const useStyles = makeStyles(theme => ({
  teamPageTemplate: {
    minHeight: '100vh',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    position: 'relative',
    padding: theme.spacing(3,3,0),
  },

  headerTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '50px',
  },

  headerIcon: {
    width: '32px',
    height: '32px',
    color: COLOR_TEXT,
  },
  headerTitle: {
    ...theme.typography.h2,
    margin: '0 0 0 12px',
  },

  headerToggle: {
    marginLeft: theme.spacing(4),
  },

  headerDropDownContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
  },
  teamDropDown: {
    width: '100%',
    maxWidth: 'none',
  },

  contentWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1260px',
    margin: '0 auto',
    padding: theme.spacing(3),
  },

  conferencesList: {
    marginLeft: theme.spacing(2),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },

  '@media (max-width: 1023px)': {
    contentWrap: {
      display: 'block',
    },

    conferencesList: {
      marginLeft: 0,
      marginTop: theme.spacing(1),

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },

  [MEDIA.MOBILE]: {
    header: {
      flexWrap: 'wrap',
    },

    headerTitleContainer: {
      flexWrap: 'wrap',
    },

    headerIcon: {
      width: '28px',
      height: '28px',
      marginBottom: theme.spacing(2),
    },
    headerTitle: {
      margin: theme.spacing(0,2,2,1),
    },

    headerToggle: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
    },

    headerDropDownContainer: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },

  [MEDIA.DESKTOP]: {
    header: {
      padding: theme.spacing(5,5,0),
    },

    teamDropDown: {
      maxWidth: '300px',
    },

    contentWrap: {
      padding: theme.spacing(4,5,5,5),
    },
  },

  [MEDIA.DESKTOP_LARGE]: {
    headerTitleContainer: {
      justifyContent: 'center',
      position: 'absolute',
      left: theme.spacing(5),
      top: theme.spacing(5),
    },

    teamDropDown: {
      margin: '0 auto',
    },

    conferencesList: {
      marginLeft: theme.spacing(4),
    },
  },

  '@media (min-width: 1680px)': {
    teamDropDown: {
      maxWidth: '520px',
    },

    conferencesList: {
      marginLeft: theme.spacing(8),
    },
  },

  '@media (min-width: 1801px)': {
    conferencesList: {
      marginLeft: theme.spacing(12.5),
    },
  },
}), { name: TeamsPageTemplate.name });

function TeamsPageTemplate (props:TeamsPageTemplateProps) {
  const {
    user,
    collegeTeams,
    collegeTeamsLoading,
    nflTeams,
    nflTeamsLoading,
    nflAccess,
    fbsConferences,
    fbsConferencesLoading,
    fcsConferences,
    fcsConferencesLoading,
    fetchCollegeTeams,
    fetchNFLTeams,
    fetchFBSConferences,
    fetchFCSConferences,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [conferencesLoading, setConferencesLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    nflAccess ? CATEGORY.NFL : CATEGORY.COLLEGE
  );
  const [nflConferences, setNFLConferences] = useState<Conference[]>([]);

  useEffect(() => {
    setSelectedCategory(nflAccess ? CATEGORY.NFL : CATEGORY.COLLEGE);
  }, [nflAccess]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.accessCollegeTeamPage && !user.accessLevel.accessNFLTeamPage) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  useEffect(() => {
    if (selectedCategory === CATEGORY.COLLEGE) {
      if (!fbsConferences.length || !fcsConferences.length) {
        fetchCollegeConferencesAndTeams();
      }
    } else if (selectedCategory === CATEGORY.NFL) {
      if (!nflConferences.length) {
        fetchNFLConferencesAndTeams();
      }
    }
  }, [selectedCategory]);

  const accessLevel = user?.accessLevel;

  function fetchCollegeConferencesAndTeams () {
    fetchFBSConferences();
    fetchFCSConferences();
    fetchCollegeTeams();
  }

  function fetchNFLConferencesAndTeams () {
    setConferencesLoading(true);

    fetchNFLConferences()
      .finally(() => setConferencesLoading(false));

    fetchNFLTeams();
  }

  function fetchNFLConferences () {
    return gql(`
      nflConferences {
        abbr
        name
        iconUrl
        teams {
          id
        }
      }
    `)
      .then((data:any) => data.nflConferences as Conference[])
      .then((nflConferences:Conference[]) => {
        setNFLConferences(nflConferences || []);
      })
      .catch(console.error);
  }

  const teamsLoading = collegeTeamsLoading || nflTeamsLoading;
  const loading = conferencesLoading || teamsLoading || fbsConferencesLoading || fcsConferencesLoading;

  return (
    <PageContainer>
      <Paper className={classes.teamPageTemplate}>
        <div className={classes.header}>
          <div className={classes.headerTitleContainer}>
            <TeamsIcon className={classes.headerIcon} />
            <h2 className={classes.headerTitle}>Teams</h2>

            {(accessLevel?.accessCollegeTeamPage && accessLevel?.accessNFLTeamPage) && (
              <Toggle
                className={classes.headerToggle}
                items={[
                  { value: CATEGORY.COLLEGE, content: 'College'},
                  { value: CATEGORY.NFL, content: 'NFL'},
                ]}
                value={selectedCategory}
                onSelect={(category:string) => setSelectedCategory(category)}
              />
            )}
          </div>

          <div className={classes.headerDropDownContainer}>
            <TeamAutoComplete
              className={classes.teamDropDown}
              loading={teamsLoading}
              teams={
                selectedCategory === CATEGORY.COLLEGE
                  ? collegeTeams
                  : nflTeams
              }
              onSelect={(team:Team | null) => team && history.push(`/team/${team.slug}`)}
            />
          </div>
        </div>

        <div className={classes.contentWrap}>
          {accessLevel?.accessCollegeTeamPage && selectedCategory === CATEGORY.COLLEGE && (
            <>
              <ConferencesList
                className={classes.conferencesList}
                divisionName='FBS'
                conferences={fbsConferences}
                teams={collegeTeams}
                loading={loading}
              />

              <ConferencesList
                className={classes.conferencesList}
                divisionName='FCS'
                conferences={fcsConferences}
                teams={collegeTeams}
                loading={loading}
              />
            </>
          )}

          {accessLevel?.accessNFLTeamPage && selectedCategory === CATEGORY.NFL && (
            <>
              <ConferencesList
                className={classes.conferencesList}
                conferences={nflConferences.length ? [nflConferences[0]] : []}
                teams={nflTeams}
                useConferenceAbbr
                loading={loading}
              />

              <ConferencesList
                className={classes.conferencesList}
                conferences={nflConferences.length ? [nflConferences[1]] : []}
                teams={nflTeams}
                useConferenceAbbr
                loading={loading}
              />
            </>
          )}
        </div>
      </Paper>
    </PageContainer>
  );
}

const mapStateToProps = (state:State) => {
  return {
    collegeTeams: state.teams.collegeTeams,
    collegeTeamsLoading: state.teams.collegeTeamsLoading,
    nflTeams: state.teams.nflTeams,
    nflTeamsLoading: state.teams.nflTeamsLoading,
    nflAccess: state.ui.nflAccess,
    fbsConferences: state.conferences.fbsConferences,
    fbsConferencesLoading: state.conferences.fbsConferencesLoading,
    fcsConferences: state.conferences.fcsConferences,
    fcsConferencesLoading: state.conferences.fcsConferencesLoading,
    user: state.user,
  };
};

const mapDispatchToProps =  (dispatch:Dispatch) => {
  return bindActionCreators(
    {
      fetchCollegeTeams,
      fetchNFLTeams,
      fetchFBSConferences,
      fetchFCSConferences,
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamsPageTemplate);
