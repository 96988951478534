import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PageContainer from './PageContainer';
import { State } from '../redux/reducers';
import {
  COLOR_TEXT,
  COLOR_WHITE,
  COLOR_DARK_GRAY
} from '../styles/colors';
import { FONT_PROXIMA_NOVA } from '../styles/fonts';
import User from '../types/User';
import ImportPage from '../organisms/ImportPage';
import MyImportPage from '../organisms/MyImportPage';
import MEDIA from '../styles/media';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ImportDepthChartPage from '../pages/ImportDepthChartPage';


interface ImportPageTemplateProps {
  user?: User;
}

enum TabName {
  PLAYERS = 'players',
  FILES = 'files',
  DEPTHCHART = 'depthChart'
}

const TAB_TITLE = {
  [TabName.PLAYERS]: 'Players',
  [TabName.FILES]: 'Files',
  [TabName.DEPTHCHART]: 'Depth Chart',
};



const useStyles = makeStyles(theme => ({
  importPageTemplate: {
    minHeight: '100vh',
  },
  tabs: {
    minHeight: 'initial',
    margin: '0 auto',
  },
  tabsIndicator: {
    height: '3px',
  },
  tab: {
    minWidth: 'auto',
    minHeight: '50px',
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    fontFamily: FONT_PROXIMA_NOVA,
    borderBottom: '1px solid transparent',
    color: COLOR_TEXT,
    textTransform: 'none',
    transition: 'border 0.3s',

    '&:hover': {
      borderBottomColor: COLOR_DARK_GRAY,
    },
  },
  tabsWrapper: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: '56px',
  },

  [MEDIA.MOBILE]: {
    tabs: {
      margin: 0,
    },
  },

  '@media (min-width: 1601px)': {
    tabsWrapper: {
      minHeight: '50px',
    },
  },

}), { name: ImportPageTemplate.name });

function ImportPageTemplate (props:ImportPageTemplateProps) {
  const classes = useStyles();
  const { user } = props;

  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.accessLevel.canImport) {
        history.push(`/dashboard?no-access=${history.location.pathname}`)
      }
    }
  }, [user]);

  if (!user || !Object.keys(user).length) {
    return null;
  }


  function onTabChange (event: React.ChangeEvent<{}>, newValue: number) {
    setSelectedTab(newValue);
  }

  return (
    <PageContainer className={classes.importPageTemplate}>
      <div className={classes.tabsWrapper}>
        <Tabs
        className={classes.tabs} classes={{ indicator: classes.tabsIndicator }}
          value={selectedTab}
          onChange={onTabChange}
          aria-label="simple tabs example"
          centered
        >
          <Tab className={classes.tab} label={TAB_TITLE[TabName.PLAYERS]} />
          <Tab className={classes.tab} label={TAB_TITLE[TabName.FILES]} />
          <Tab className={classes.tab} label={TAB_TITLE[TabName.DEPTHCHART]} />
        </Tabs>
      </div>
      <Box p={3}>
        {selectedTab === 0 && <ImportPage />}
        {selectedTab === 1 && <MyImportPage />}
        {selectedTab === 2 && <ImportDepthChartPage />}
      </Box>
    </PageContainer>
  )
}

const mapStateToProps = (state:State) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
)(ImportPageTemplate);
