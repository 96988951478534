import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CheckIcon from '../icons/CheckIcon';
import Checkbox from '../atoms/Checkbox';
import Tooltip from '../atoms/Tooltip';
import NotAvailable from '../atoms/NotAvailable';
import DropDown from '../atoms/DropDown';
import Input from '../atoms/Input';
import Score, { SCORE_TYPE } from '../atoms/Score';
import EditTrackEventPopover from '../molecules/EditTrackEventPopover';
import MultiSelect from './MultiSelect';
import TeamAutoComplete from './TeamAutoComplete';
import {
  datetimeToDate,
  inchesToFeetInInchesAsSingleNumber,
  secondsToMinutesSecondsMs,
} from '../services/converter';
import { formatDate, sortDraftRound } from '../services/player';
import { validatePrimaryPosition, validatePositions } from '../services/positions';
import {
  COLOR_BACKGROUND_LIGHT,
  COLOR_GREEN,
  COLOR_LIGHT_ORANGE,
  COLOR_LIGHT_RED,
  COLOR_MIDDLE_GRAY,
  COLOR_RED,
  COLOR_WHITE,
} from '../styles/colors';
import StagedPlayer, { StagedPlayerTrackEvent, StagedPlayerValidationError } from '../types/StagedPlayer';
import ValidationRule, { ValidationRuleType } from '../types/ValidationRule';
import { Order } from '../types/Order';
import StateType from '../types/State';
import Team from '../types/Team';
import ValidationResult from '../types/ValidationResult';
import HsCombineType from '../types/HsCombineType';
import DatePicker from '../atoms/DatePicker';

interface StagedPlayerTableRowProps {
  className?: string;
  player: StagedPlayer;
  validationRules: ValidationRule[];
  states: StateType[];
  divITeams: Team[];
  collegeTeams: Team[];
  hsCombineTypes: HsCombineType[]
  nflTeams: Team[];
  teamsOnly: Team[];
  editedPlayer: StagedPlayer | undefined;
  editedColumn: StagedPlayerColumn | undefined;
  checked: boolean;
  onSelectPlayer: (player:StagedPlayer) => (value:boolean) => void;
  onEditColumn: (
    player:StagedPlayer | undefined,
    columnKey:StagedPlayerColumn | undefined,
    newValue?:any | null,
  ) => void;
}

export enum StagedPlayerColumn {
  STATUS = 'status',
  OURLADS_ID = 'ourladsId',
  SLUG = 'slug',
  KEY_247 = 'key247',
  // UNIQUE_ID = 'uniqueId',
  PFF_ID = 'pffId',
  SSA_ID = 'ssaId',
  RYZER_ID = 'ryzerId',
  RYZER_URL = 'ryzerUrl',
  LAST_EDITED = 'lastPublishedDate',
  ARCHIVED = 'isArchived',
  PAI = 'pai',
  COMBINE = 'combine',
  PPI_SCORE = 'ppi',
  HS_CLASS = 'hsClass',
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  HS_STATE = 'hsState',
  HS_NAME = 'hsName',
  HS_CITY = 'hsCity',
  HS_COUNTY = 'hsCounty',
  HS_ZIP_CODE = 'hsZipcode',
  HS_PRIMARY_POSITION = 'hsPrimaryPosition',
  HS_POSITIONS = 'hsPositions',
  HS_HEIGHT = 'hsHeight',
  HS_WEIGHT = 'hsWeight',
  DOB = 'dob',
  GPA = 'gpa',
  SAT = 'sat',
  ACT = 'act',
  COMP_247 = 'comp247',
  COMP_STAR = 'compStar',
  STAR_247 = 'star247',
  OFFER_COUNT = 'offersCount',
  OFFER_SCHOOLS = 'offers',
  COMMITTED_SCHOOL = 'committedTo',
  COMMITTED_DATE = 'committedDate',
  TF_URL = 'linkTrackingFootball',
  PLAYER_PHOTO = 'photoUrl',
  URL_247 = 'link247',
  RIVALS_URL = 'linkRivals',
  ON3_URL = 'linkOn3',
  HUDL_URL = 'linkHudl',
  MAXPREPS_URL = 'linkMaxpreps',
  TWITTER_URL = 'linkTwitter',
  INSTAGRAM_URL = 'linkInstagram',
  ATHLETIC_URL = 'linkAthleticNet',
  MILESPLIT_URL = 'linkMilesplit',
  DIRECT_ATHLETICS_URL = 'linkDirectAthletics',
  MISC_URL = 'miscUrl',
  BASEBALL = 'hsSportBaseball',
  BASKETBALL = 'hsSportBasketball',
  GOLF = 'hsSportGolf',
  HOCKEY = 'hsSportHockey',
  LACROSSE = 'hsSportLacrosse',
  POWERLIFTING = 'hsSportPowerLifting',
  RUGBY = 'hsSportRugby',
  SOCCER = 'hsSportSoccer',
  SWIMMING = 'hsSportSwimming',
  TENNIS = 'hsSportTennis',
  VOLLEYBALL = 'hsSportVolleyball',
  WRESTLING = 'hsSportWrestling',
  TRACK = 'hsSportTrack',
  TRACK_55M = 'track55m',
  TRACK_60M = 'track60m',
  TRACK_55HH = 'track55hh',
  TRACK_60HH = 'track60hh',
  TRACK_100M = 'track100m',
  TRACK_110HH = 'track110hh',
  TRACK_200M = 'track200m',
  TRACK_300M = 'track300m',
  TRACK_300IH = 'track300ih',
  TRACK_400M = 'track400m',
  TRACK_400H = 'track400h',
  TRACK_800M = 'track800m',
  TRACK_400R = 'track400r',
  TRACK_800R = 'track800r',
  TRACK_1600R = 'track1600r',
  TRACK_3200R = 'track3200r',
  TRACK_SHOTPUT = 'trackShotput',
  TRACK_DISCUS = 'trackDiscus',
  TRACK_JAVELIN = 'trackJavelin',
  TRACK_HIGH_JUMP = 'trackHighJump',
  TRACK_LONG_JUMP = 'trackLongJump',
  TRACK_TRIPLE_JUMP = 'trackTripleJump',
  TRACK_POLE_VAULT = 'trackPoleVault',
  TRACK_A_TIER = 'aTier',
  TRACK_B_TIER = 'bTier',
  TRACK_C_TIER = 'cTier',
  SPEED_TAG = 'speedTag',
  POWER_TAG = 'powerTag',
  DISCOVERY = 'discovery',
  HS_COMBINE_EVENT = 'hsCombineType',
  HS_COMBINE_TEAM_CAMP = 'hsCombineTeamCamp',
  HS_COMBINE_LOCATION = 'hsCombineCity',
  HS_COMBINE_DATE = 'hsCombineDate',
  HS_COMBINE_HEIGHT = 'hsCombineHeight',
  HS_COMBINE_WEIGHT = 'hsCombineWeight',
  HS_COMBINE_WINGSPAN = 'hsCombineWingspan',
  HS_COMBINE_HAND = 'hsCombineHand',
  HS_COMBINE_ARM = 'hsCombineArm',
  HS_COMBINE_MPH = 'hsCombineMPH',
  HS_COMBINE_10_SPLIT = 'hsCombine10Split',
  HS_COMBINE_40M_DASH = 'hsCombine40mDash',
  HS_COMBINE_SHUTTLE = 'hsCombineShuttle',
  HS_COMBINE_3_CONE = 'hsCombine3Cone',
  HS_COMBINE_VERTICAL = 'hsCombineVerticalJump',
  HS_COMBINE_BROAD = 'hsCombineBroadJump',
  HS_COMBINE_POWER_TOSS = 'hsCombinePowerToss',
  HS_COMBINE_RATING = 'hsCombineRating',
  ABOUT = 'about',
  PHONE = 'cellPhone',
  EMAIL = 'email',
  ADDRESS = 'address',
  CITY_STATE_ZIP = 'cityStateZip',
  PARENT_NAME = 'parentNames',
  PARENT_CONTACT = 'parentContact',
  HEAD_COACH = 'headCoach',
  HEAD_COACH_CONTACT = 'headCoachContact',
  HS_FILM_URL ='hsFilmUrl',
  HS_MPH ='hsMPH',
  HS_MPH_PERCENTAGE ='hsMPHPercentage',
  HS_TIME_TO_MAX_SPEED ='hsTimeToMaxSpeed',
  HS_DISTANCE_TRAVELLED ='hsDistanceTraveled',
  COLLEGE_MPH ='collegeMPH',
  COLLEGE_MPH_PERCENTAGE ='collegeMPHPercentage',
  PREP = 'isPrep',
  PREP_NAME = 'prepName',
  TRANSFER_STATUS = 'transferStatus',
  TRANSFER_YEAR = 'transferYear',
  NCAA_ID = 'ncaaId',
  COLLEGE_SCHOLARSHIP = 'collegeScholarship',
  COLLEGE_COMMITTED_TO = 'collegeCommittedTo',
  COLLEGE_ACTIVE = 'collegeActive',
  DEPTH_CHART = 'depthChart',
  COLLEGE_URL = 'collegeUrl',
  PFF_URL = 'linkPff',
  SSA_URL = 'linkSsa',
  COLLEGE_NAME = 'collegeName',
  COLLEGE_RECRUITING_CLASS = 'collegeRecruitingClass',
  COLLEGE_PRIMARY_POSITION = 'collegePrimaryPosition',
  COLLEGE_POSITIONS = 'collegePositions',
  COLLEGE_HEIGHT = 'collegeHeight',
  COLLEGE_WEIGHT = 'collegeWeight',
  COLLEGE_JERSEY_NUMBER = 'collegeJerseyNumber',
  ALL_CONFERENCE = 'collegeAllConference',
  ALL_AMERICAN = 'collegeAllAmerican',
  WATCH_LIST_EVENT = 'watchlistEvent',
  WATCH_LIST_YEAR = 'watchlistYear',
  PRO_COMBINE = 'nflCombineType',
  NFL_COMBINE_YEAR = 'nflCombineDate',
  NFL_COMBINE_SCORE = 'nflCombineScore',
  NFL_PRIMARY_POSITION = 'nflPrimaryPosition',
  NFL_POSITIONS = 'nflPositions',
  NFL_HEIGHT = 'nflCombineHeight',
  NFL_WEIGHT = 'nflCombineWeight',
  NFL_HAND = 'nflCombineHand',
  NFL_WINGSPAN = 'nflCombineWingspan',
  NFL_ARM = 'nflCombineArm',
  NFL_40 = 'nflCombine40mDash',
  NFL_10_SPLIT = 'nflCombine10Split',
  NFL_20_SPLIT = 'nflCombine20Split',
  NFL_MPH = 'nflCombineMPH',
  NFL_SHUTTLE = 'nflCombineShuttle',
  NFL_3_CONE = 'nflCombine3Cone',
  NFL_VERTICAL = 'nflCombineVerticalJump',
  NFL_BROAD = 'nflCombineBroadJump',
  NFL_BENCH = 'nflCombineBench',
  NFL_TEAM_NAME = 'nflTeamName',
  NFL_DRAFT_YEAR = 'nflDraftYear',
  NFL_DRAFT_ROUND = 'nflDraftRound',
  OVERALL_PICK = 'nflOverallPick',
  PRO_BOWL = 'nflProBowl',
  ALL_PRO = 'nflAllPro',
  PPI_DATE = 'ppiDate'
}

const COLUMNS_IN_ORDER = [
  StagedPlayerColumn.STATUS,
  StagedPlayerColumn.SLUG,
  StagedPlayerColumn.OURLADS_ID,
  StagedPlayerColumn.KEY_247,
  // StagedPlayerColumn.UNIQUE_ID,
  StagedPlayerColumn.PFF_ID,
  StagedPlayerColumn.SSA_ID,
  StagedPlayerColumn.RYZER_ID,
  StagedPlayerColumn.RYZER_URL,
  StagedPlayerColumn.LAST_EDITED,
  StagedPlayerColumn.ARCHIVED,
  StagedPlayerColumn.PAI,
  StagedPlayerColumn.PPI_SCORE,
  StagedPlayerColumn.PPI_DATE,
  StagedPlayerColumn.COMBINE,
  StagedPlayerColumn.HS_CLASS,
  StagedPlayerColumn.LAST_NAME,
  StagedPlayerColumn.FIRST_NAME,
  StagedPlayerColumn.HS_STATE,
  StagedPlayerColumn.HS_NAME,
  StagedPlayerColumn.HS_CITY,
  StagedPlayerColumn.HS_COUNTY,
  StagedPlayerColumn.HS_ZIP_CODE,
  StagedPlayerColumn.HS_PRIMARY_POSITION,
  StagedPlayerColumn.HS_POSITIONS,
  StagedPlayerColumn.HS_HEIGHT,
  StagedPlayerColumn.HS_WEIGHT,
  StagedPlayerColumn.DOB,
  StagedPlayerColumn.GPA,
  StagedPlayerColumn.SAT,
  StagedPlayerColumn.ACT,
  StagedPlayerColumn.COMP_247,
  StagedPlayerColumn.COMP_STAR,
  StagedPlayerColumn.STAR_247,
  StagedPlayerColumn.OFFER_COUNT,
  StagedPlayerColumn.OFFER_SCHOOLS,
  StagedPlayerColumn.COMMITTED_SCHOOL,
  StagedPlayerColumn.COMMITTED_DATE,
  StagedPlayerColumn.TF_URL,
  StagedPlayerColumn.PLAYER_PHOTO,
  StagedPlayerColumn.URL_247,
  StagedPlayerColumn.RIVALS_URL,
  StagedPlayerColumn.ON3_URL,
  StagedPlayerColumn.HUDL_URL,
  StagedPlayerColumn.MAXPREPS_URL,
  StagedPlayerColumn.TWITTER_URL,
  StagedPlayerColumn.INSTAGRAM_URL,
  StagedPlayerColumn.ATHLETIC_URL,
  StagedPlayerColumn.MILESPLIT_URL,
  StagedPlayerColumn.DIRECT_ATHLETICS_URL,
  StagedPlayerColumn.MISC_URL,
  StagedPlayerColumn.BASEBALL,
  StagedPlayerColumn.BASKETBALL,
  StagedPlayerColumn.GOLF,
  StagedPlayerColumn.HOCKEY,
  StagedPlayerColumn.LACROSSE,
  StagedPlayerColumn.POWERLIFTING,
  StagedPlayerColumn.RUGBY,
  StagedPlayerColumn.SOCCER,
  StagedPlayerColumn.SWIMMING,
  StagedPlayerColumn.TENNIS,
  StagedPlayerColumn.TRACK,
  StagedPlayerColumn.VOLLEYBALL,
  StagedPlayerColumn.WRESTLING,
  StagedPlayerColumn.TRACK_55M,
  StagedPlayerColumn.TRACK_60M,
  StagedPlayerColumn.TRACK_55HH,
  StagedPlayerColumn.TRACK_60HH,
  StagedPlayerColumn.TRACK_100M,
  StagedPlayerColumn.TRACK_110HH,
  StagedPlayerColumn.TRACK_200M,
  StagedPlayerColumn.TRACK_300M,
  StagedPlayerColumn.TRACK_300IH,
  StagedPlayerColumn.TRACK_400M,
  StagedPlayerColumn.TRACK_400H,
  StagedPlayerColumn.TRACK_800M,
  StagedPlayerColumn.TRACK_400R,
  StagedPlayerColumn.TRACK_800R,
  StagedPlayerColumn.TRACK_1600R,
  StagedPlayerColumn.TRACK_3200R,
  StagedPlayerColumn.TRACK_SHOTPUT,
  StagedPlayerColumn.TRACK_DISCUS,
  StagedPlayerColumn.TRACK_JAVELIN,
  StagedPlayerColumn.TRACK_HIGH_JUMP,
  StagedPlayerColumn.TRACK_LONG_JUMP,
  StagedPlayerColumn.TRACK_TRIPLE_JUMP,
  StagedPlayerColumn.TRACK_POLE_VAULT,
  StagedPlayerColumn.TRACK_A_TIER,
  StagedPlayerColumn.TRACK_B_TIER,
  StagedPlayerColumn.TRACK_C_TIER,
  StagedPlayerColumn.SPEED_TAG,
  StagedPlayerColumn.POWER_TAG,
  StagedPlayerColumn.DISCOVERY,
  StagedPlayerColumn.HS_COMBINE_EVENT,
  StagedPlayerColumn.HS_COMBINE_TEAM_CAMP,
  StagedPlayerColumn.HS_COMBINE_LOCATION,
  StagedPlayerColumn.HS_COMBINE_DATE,
  StagedPlayerColumn.HS_COMBINE_HEIGHT,
  StagedPlayerColumn.HS_COMBINE_WEIGHT,
  StagedPlayerColumn.HS_COMBINE_WINGSPAN,
  StagedPlayerColumn.HS_COMBINE_HAND,
  StagedPlayerColumn.HS_COMBINE_ARM,
  StagedPlayerColumn.HS_COMBINE_MPH,
  StagedPlayerColumn.HS_COMBINE_10_SPLIT,
  StagedPlayerColumn.HS_COMBINE_40M_DASH,
  StagedPlayerColumn.HS_COMBINE_SHUTTLE,
  StagedPlayerColumn.HS_COMBINE_3_CONE,
  StagedPlayerColumn.HS_COMBINE_VERTICAL,
  StagedPlayerColumn.HS_COMBINE_BROAD,
  StagedPlayerColumn.HS_COMBINE_POWER_TOSS,
  StagedPlayerColumn.HS_COMBINE_RATING,
  StagedPlayerColumn.ABOUT,
  StagedPlayerColumn.PHONE,
  StagedPlayerColumn.EMAIL,
  StagedPlayerColumn.ADDRESS,
  StagedPlayerColumn.CITY_STATE_ZIP,
  StagedPlayerColumn.PARENT_NAME,
  StagedPlayerColumn.PARENT_CONTACT,
  StagedPlayerColumn.HEAD_COACH,
  StagedPlayerColumn.HEAD_COACH_CONTACT,
  StagedPlayerColumn.HS_FILM_URL,
  StagedPlayerColumn.HS_MPH,
  StagedPlayerColumn.HS_MPH_PERCENTAGE,
  StagedPlayerColumn.HS_TIME_TO_MAX_SPEED,
  StagedPlayerColumn.HS_DISTANCE_TRAVELLED,
  StagedPlayerColumn.COLLEGE_MPH,
  StagedPlayerColumn.COLLEGE_MPH_PERCENTAGE,
  StagedPlayerColumn.PREP,
  StagedPlayerColumn.PREP_NAME,
  StagedPlayerColumn.TRANSFER_STATUS,
  StagedPlayerColumn.TRANSFER_YEAR,
  StagedPlayerColumn.NCAA_ID,
  StagedPlayerColumn.COLLEGE_SCHOLARSHIP,
  StagedPlayerColumn.COLLEGE_COMMITTED_TO,
  StagedPlayerColumn.COLLEGE_ACTIVE,
  StagedPlayerColumn.DEPTH_CHART,
  StagedPlayerColumn.COLLEGE_URL,
  StagedPlayerColumn.SSA_URL,
  StagedPlayerColumn.PFF_URL,
  StagedPlayerColumn.COLLEGE_NAME,
  StagedPlayerColumn.COLLEGE_RECRUITING_CLASS,
  StagedPlayerColumn.COLLEGE_PRIMARY_POSITION,
  StagedPlayerColumn.COLLEGE_POSITIONS,
  StagedPlayerColumn.COLLEGE_HEIGHT,
  StagedPlayerColumn.COLLEGE_WEIGHT,
  StagedPlayerColumn.COLLEGE_JERSEY_NUMBER,
  StagedPlayerColumn.ALL_CONFERENCE,
  StagedPlayerColumn.ALL_AMERICAN,
  StagedPlayerColumn.WATCH_LIST_EVENT,
  StagedPlayerColumn.WATCH_LIST_YEAR,
  StagedPlayerColumn.PRO_COMBINE,
  StagedPlayerColumn.NFL_COMBINE_YEAR,
  StagedPlayerColumn.NFL_COMBINE_SCORE,
  StagedPlayerColumn.NFL_PRIMARY_POSITION,
  StagedPlayerColumn.NFL_POSITIONS,
  StagedPlayerColumn.NFL_HEIGHT,
  StagedPlayerColumn.NFL_WEIGHT,
  StagedPlayerColumn.NFL_HAND,
  StagedPlayerColumn.NFL_WINGSPAN,
  StagedPlayerColumn.NFL_ARM,
  StagedPlayerColumn.NFL_40,
  StagedPlayerColumn.NFL_10_SPLIT,
  StagedPlayerColumn.NFL_20_SPLIT,
  StagedPlayerColumn.NFL_MPH,
  StagedPlayerColumn.NFL_SHUTTLE,
  StagedPlayerColumn.NFL_3_CONE,
  StagedPlayerColumn.NFL_VERTICAL,
  StagedPlayerColumn.NFL_BROAD,
  StagedPlayerColumn.NFL_BENCH,
  StagedPlayerColumn.NFL_TEAM_NAME,
  StagedPlayerColumn.NFL_DRAFT_YEAR,
  StagedPlayerColumn.NFL_DRAFT_ROUND,
  StagedPlayerColumn.OVERALL_PICK,
  StagedPlayerColumn.PRO_BOWL,
  StagedPlayerColumn.ALL_PRO,
];

export const COLUMN_TITLE = {
  [StagedPlayerColumn.STATUS]: 'Status',
  [StagedPlayerColumn.SLUG]: 'Slug',
  [StagedPlayerColumn.KEY_247]: '247 Key',
  // [StagedPlayerColumn.UNIQUE_ID]: 'Unique ID',
  [StagedPlayerColumn.PFF_ID]: 'PFF ID',
  [StagedPlayerColumn.SSA_ID]: 'SSA ID',
  [StagedPlayerColumn.RYZER_ID]: 'Ryzer ID',
  [StagedPlayerColumn.LAST_EDITED]: 'Last Edited',
  [StagedPlayerColumn.ARCHIVED]: 'Archived',
  [StagedPlayerColumn.PAI]: 'PAI',
  [StagedPlayerColumn.COMBINE]: 'Combine',
  [StagedPlayerColumn.PPI_SCORE]: 'PPI Score',
  [StagedPlayerColumn.PPI_DATE]: 'PPI Date',
  [StagedPlayerColumn.HS_CLASS]: 'HS Class',
  [StagedPlayerColumn.LAST_NAME]: 'Last',
  [StagedPlayerColumn.FIRST_NAME]: 'First',
  [StagedPlayerColumn.HS_STATE]: 'HS State',
  [StagedPlayerColumn.HS_NAME]: 'HS Name',
  [StagedPlayerColumn.HS_CITY]: 'HS City',
  [StagedPlayerColumn.HS_COUNTY]: 'HS County',
  [StagedPlayerColumn.HS_ZIP_CODE]: 'Zip Code (HS)',
  [StagedPlayerColumn.HS_PRIMARY_POSITION]: 'HS Primary Pos',
  [StagedPlayerColumn.HS_POSITIONS]: 'HS Positions',
  [StagedPlayerColumn.HS_HEIGHT]: 'HS Height',
  [StagedPlayerColumn.HS_WEIGHT]: 'HS Weight',
  [StagedPlayerColumn.DOB]: 'DOB',
  [StagedPlayerColumn.GPA]: 'GPA',
  [StagedPlayerColumn.SAT]: 'SAT',
  [StagedPlayerColumn.ACT]: 'ACT',
  [StagedPlayerColumn.COMP_247]: '247 Comp',
  [StagedPlayerColumn.COMP_STAR]: 'Comp Star',
  [StagedPlayerColumn.STAR_247]: '247 Star',
  [StagedPlayerColumn.OFFER_COUNT]: 'Offer Count',
  [StagedPlayerColumn.OFFER_SCHOOLS]: 'Offer Schools',
  [StagedPlayerColumn.COMMITTED_SCHOOL]: 'Committed School',
  [StagedPlayerColumn.COMMITTED_DATE]: 'Committed Date',
  [StagedPlayerColumn.TF_URL]: 'TF URL',
  [StagedPlayerColumn.PLAYER_PHOTO]: 'Player Photo',
  [StagedPlayerColumn.URL_247]: '247 URL',
  [StagedPlayerColumn.RIVALS_URL]: 'Rivals URL',
  [StagedPlayerColumn.ON3_URL]: 'On3 URL',
  [StagedPlayerColumn.HUDL_URL]: 'Hudl URL',
  [StagedPlayerColumn.MAXPREPS_URL]: 'Maxpreps URL',
  [StagedPlayerColumn.TWITTER_URL]: 'Twitter URL',
  [StagedPlayerColumn.INSTAGRAM_URL]: 'Instagram URL',
  [StagedPlayerColumn.ATHLETIC_URL]: 'Athletic URL',
  [StagedPlayerColumn.MILESPLIT_URL]: 'Milesplit URL',
  [StagedPlayerColumn.DIRECT_ATHLETICS_URL]: 'DirectAthletics URL',
  [StagedPlayerColumn.BASEBALL]: 'Baseball',
  [StagedPlayerColumn.BASKETBALL]: 'Basketball',
  [StagedPlayerColumn.GOLF]: 'Golf',
  [StagedPlayerColumn.HOCKEY]: 'Hockey',
  [StagedPlayerColumn.LACROSSE]: 'Lacrosse',
  [StagedPlayerColumn.POWERLIFTING]: 'Powerlifting',
  [StagedPlayerColumn.RUGBY]: 'Rugby',
  [StagedPlayerColumn.SOCCER]: 'Soccer',
  [StagedPlayerColumn.SWIMMING]: 'Swimming',
  [StagedPlayerColumn.TENNIS]: 'Tennis',
  [StagedPlayerColumn.TRACK]: 'Track',
  [StagedPlayerColumn.VOLLEYBALL]: 'Volleyball',
  [StagedPlayerColumn.WRESTLING]: 'Wrestling',
  [StagedPlayerColumn.TRACK_55M]: '55M',
  [StagedPlayerColumn.TRACK_60M]: '60M',
  [StagedPlayerColumn.TRACK_55HH]: '55HH',
  [StagedPlayerColumn.TRACK_60HH]: '60HH',
  [StagedPlayerColumn.TRACK_100M]: '100M',
  [StagedPlayerColumn.TRACK_110HH]: '110HH',
  [StagedPlayerColumn.TRACK_200M]: '200M',
  [StagedPlayerColumn.TRACK_300M]: '300M',
  [StagedPlayerColumn.TRACK_300IH]: '300IH',
  [StagedPlayerColumn.TRACK_400M]: '400M',
  [StagedPlayerColumn.TRACK_400H]: '400H',
  [StagedPlayerColumn.TRACK_800M]: '800',
  [StagedPlayerColumn.TRACK_400R]: '400R',
  [StagedPlayerColumn.TRACK_800R]: '800R',
  [StagedPlayerColumn.TRACK_1600R]: '1600R',
  [StagedPlayerColumn.TRACK_3200R]: '3200R',
  [StagedPlayerColumn.TRACK_SHOTPUT]: 'Shot Put',
  [StagedPlayerColumn.TRACK_DISCUS]: 'Discus',
  [StagedPlayerColumn.TRACK_JAVELIN]: 'Javelin',
  [StagedPlayerColumn.TRACK_HIGH_JUMP]: 'High Jump',
  [StagedPlayerColumn.TRACK_LONG_JUMP]: 'Long Jump',
  [StagedPlayerColumn.TRACK_TRIPLE_JUMP]: 'Triple Jump',
  [StagedPlayerColumn.TRACK_POLE_VAULT]: 'Pole Vault',
  [StagedPlayerColumn.TRACK_A_TIER]: 'A Tier',
  [StagedPlayerColumn.TRACK_B_TIER]: 'B Tier',
  [StagedPlayerColumn.TRACK_C_TIER]: 'C Tier',
  [StagedPlayerColumn.HS_COMBINE_EVENT]: 'HS Combine Event',
  [StagedPlayerColumn.HS_COMBINE_LOCATION]: 'HS Combine Location',
  [StagedPlayerColumn.HS_COMBINE_DATE]: 'HS Combine Date',
  [StagedPlayerColumn.HS_COMBINE_HEIGHT]: 'Combine Height',
  [StagedPlayerColumn.HS_COMBINE_WEIGHT]: 'Combine Weight',
  [StagedPlayerColumn.HS_COMBINE_WINGSPAN]: 'Wingspan',
  [StagedPlayerColumn.HS_COMBINE_HAND]: 'Hand',
  [StagedPlayerColumn.HS_COMBINE_ARM]: 'Arm',
  [StagedPlayerColumn.HS_COMBINE_MPH]: 'MPH',
  [StagedPlayerColumn.HS_COMBINE_10_SPLIT]: '10 Split',
  [StagedPlayerColumn.HS_COMBINE_40M_DASH]: '40 Dash',
  [StagedPlayerColumn.HS_COMBINE_SHUTTLE]: 'Shuttle',
  [StagedPlayerColumn.HS_COMBINE_3_CONE]: '3-Cone',
  [StagedPlayerColumn.HS_COMBINE_VERTICAL]: 'Vertical',
  [StagedPlayerColumn.HS_COMBINE_BROAD]: 'Broad',
  [StagedPlayerColumn.HS_COMBINE_POWER_TOSS]: 'Power Toss',
  [StagedPlayerColumn.HS_COMBINE_RATING]: 'Rating',
  [StagedPlayerColumn.ABOUT]: 'About',
  [StagedPlayerColumn.PREP]: 'Prep',
  [StagedPlayerColumn.PREP_NAME]: 'Prep Name',
  [StagedPlayerColumn.TRANSFER_STATUS]: 'Transfer Status',
  [StagedPlayerColumn.TRANSFER_YEAR]: 'Transfer Year',
  [StagedPlayerColumn.NCAA_ID]: 'Transfer NCAA ID',
  [StagedPlayerColumn.COLLEGE_SCHOLARSHIP]: 'Scholarship',
  [StagedPlayerColumn.COLLEGE_COMMITTED_TO]: 'College Committed To',
  [StagedPlayerColumn.COLLEGE_ACTIVE]: 'College Active',
  [StagedPlayerColumn.COLLEGE_URL]: 'College URL',
  [StagedPlayerColumn.PFF_URL]: 'PFF Ultimate URL',
  [StagedPlayerColumn.SSA_URL]: 'SSA URL',
  [StagedPlayerColumn.COLLEGE_NAME]: 'College Name',
  [StagedPlayerColumn.COLLEGE_RECRUITING_CLASS]: 'Recruiting Class',
  [StagedPlayerColumn.COLLEGE_PRIMARY_POSITION]: 'College Primary Pos',
  [StagedPlayerColumn.COLLEGE_POSITIONS]: 'College Positions',
  [StagedPlayerColumn.COLLEGE_HEIGHT]: 'College Height',
  [StagedPlayerColumn.COLLEGE_WEIGHT]: 'College Weight',
  [StagedPlayerColumn.COLLEGE_JERSEY_NUMBER]: 'College Jersey #',
  [StagedPlayerColumn.ALL_CONFERENCE]: 'All-Conference',
  [StagedPlayerColumn.ALL_AMERICAN]: 'All-American',
  [StagedPlayerColumn.WATCH_LIST_EVENT]: 'Watch List Event ',
  [StagedPlayerColumn.WATCH_LIST_YEAR]: 'Watch List Year',
  [StagedPlayerColumn.PRO_COMBINE]: 'Pro Combine',
  [StagedPlayerColumn.NFL_COMBINE_YEAR]: 'NFL Combine Year',
  [StagedPlayerColumn.NFL_COMBINE_SCORE]: 'NFL Combine Score',
  [StagedPlayerColumn.NFL_PRIMARY_POSITION]: 'NFL Primary Pos',
  [StagedPlayerColumn.NFL_POSITIONS]: 'NFL Positions',
  [StagedPlayerColumn.NFL_HEIGHT]: 'NFL Height',
  [StagedPlayerColumn.NFL_WEIGHT]: 'NFL Weight',
  [StagedPlayerColumn.NFL_HAND]: 'NFL Hand',
  [StagedPlayerColumn.NFL_WINGSPAN]: 'NFL Wingspan',
  [StagedPlayerColumn.NFL_ARM]: 'NFL Arm',
  [StagedPlayerColumn.NFL_40]: 'NFL 40',
  [StagedPlayerColumn.NFL_10_SPLIT]: 'NFL 10 Split',
  [StagedPlayerColumn.NFL_20_SPLIT]: 'NFL 20 Split',
  [StagedPlayerColumn.NFL_MPH]: 'NFL MPH',
  [StagedPlayerColumn.NFL_SHUTTLE]: 'NFL Shuttle',
  [StagedPlayerColumn.NFL_3_CONE]: 'NFL 3-Cone',
  [StagedPlayerColumn.NFL_VERTICAL]: 'NFL Vertical',
  [StagedPlayerColumn.NFL_BROAD]: 'NFL Broad',
  [StagedPlayerColumn.NFL_BENCH]: 'NFL Bench',
  [StagedPlayerColumn.NFL_TEAM_NAME]: 'NFL Team',
  [StagedPlayerColumn.NFL_DRAFT_YEAR]: 'Draft Yr',
  [StagedPlayerColumn.NFL_DRAFT_ROUND]: 'Draft Rd',
  [StagedPlayerColumn.OVERALL_PICK]: 'Overall Pick',
  [StagedPlayerColumn.PRO_BOWL]: 'Pro Bowl',
  [StagedPlayerColumn.ALL_PRO]: 'All-Pro',

  [StagedPlayerColumn.OURLADS_ID]: 'Ourlads Id',
  [StagedPlayerColumn.RYZER_URL]: 'Ryzer URL',
  [StagedPlayerColumn.MISC_URL]: 'Misc URL',
  [StagedPlayerColumn.SPEED_TAG]: 'Speed Tag',
  [StagedPlayerColumn.POWER_TAG]: 'Power Tag',
  [StagedPlayerColumn.DISCOVERY]: 'Discovery',
  [StagedPlayerColumn.HS_COMBINE_TEAM_CAMP]: 'HS Team Camp',
  [StagedPlayerColumn.PHONE]: 'Phone',
  [StagedPlayerColumn.EMAIL]: 'Email',
  [StagedPlayerColumn.ADDRESS]: 'Address',
  [StagedPlayerColumn.CITY_STATE_ZIP]: 'City state zip',
  [StagedPlayerColumn.PARENT_NAME]: 'Parent Name',
  [StagedPlayerColumn.PARENT_CONTACT]: 'Parent Contact',
  [StagedPlayerColumn.HEAD_COACH]: 'Head Coach',
  [StagedPlayerColumn.HEAD_COACH_CONTACT]: 'Head Coach Contact',
  [StagedPlayerColumn.HS_FILM_URL]: 'HS Film URL',
  [StagedPlayerColumn.HS_MPH]: 'HS MPH',
  [StagedPlayerColumn.HS_MPH_PERCENTAGE]: 'HS MPH Percentage',
  [StagedPlayerColumn.HS_TIME_TO_MAX_SPEED]: 'HS Time to Max Speed',
  [StagedPlayerColumn.HS_DISTANCE_TRAVELLED]: 'HS Distance Traveled',
  [StagedPlayerColumn.COLLEGE_MPH]: 'College MPH',
  [StagedPlayerColumn.COLLEGE_MPH_PERCENTAGE]: 'College MPH Percentage',
  [StagedPlayerColumn.DEPTH_CHART]: 'Depth Chart',
};

export const TRACK_EVENT_COLUMNS = [
  StagedPlayerColumn.TRACK_55M,
  StagedPlayerColumn.TRACK_60M,
  StagedPlayerColumn.TRACK_55HH,
  StagedPlayerColumn.TRACK_60HH,
  StagedPlayerColumn.TRACK_100M,
  StagedPlayerColumn.TRACK_110HH,
  StagedPlayerColumn.TRACK_200M,
  StagedPlayerColumn.TRACK_300M,
  StagedPlayerColumn.TRACK_300IH,
  StagedPlayerColumn.TRACK_400M,
  StagedPlayerColumn.TRACK_400H,
  StagedPlayerColumn.TRACK_800M,
  StagedPlayerColumn.TRACK_400R,
  StagedPlayerColumn.TRACK_800R,
  StagedPlayerColumn.TRACK_1600R,
  StagedPlayerColumn.TRACK_3200R,
  StagedPlayerColumn.TRACK_SHOTPUT,
  StagedPlayerColumn.TRACK_DISCUS,
  StagedPlayerColumn.TRACK_JAVELIN,
  StagedPlayerColumn.TRACK_HIGH_JUMP,
  StagedPlayerColumn.TRACK_LONG_JUMP,
  StagedPlayerColumn.TRACK_TRIPLE_JUMP,
  StagedPlayerColumn.TRACK_POLE_VAULT,
];

const SECONDS_PER_MINUTE = 60;

export const STATUS = {
  NEW: 'New',
  UPDATED: 'Updated',
};

enum EditType {
  TEXT = 'text',
  DROPDOWN = 'dropdown',
  MULTISELECT = 'multiselect',
  AUTOCOMPLETE = 'autocomplete',
  EDIT_TRACK_EVENT = 'edit-track-event',
  CHECKBOX = 'checkbox',
  EDIT_DATE = 'editDate'
}


const uncommitedDemoTeam: Team[] = [{
  "id": 0,
  "slug": "uncommitted",
  "type": "College",
  "name": "uncommitted",
  "shortName": "uncommitted",
  "logo247": "",
  "logoESPN": "",
  "logoAlt": "",
  "averagePAI": 0,
  "totalWithPAI": 0,
  "playerCount": 0,
  "pai": [],
  "combine": [],
  "proCombine": [],
  "ppi": [],
  "ppiDate": '',
  "conference": {
    "id": 1,
    "name": "",
    "abbr": "",
    "iconUrl": "",
    "slug": "",
    "mainLevel": "",
    "paiScore": 0,
    "combineScore": 0,
    "averagePAI": 0,
    "averageCombine": 0,
    "division": "",
  },
  "level": "",
  "city": "",
  "state": "",
  "stateAbbr": "",
  "positionStats": "",
  "recruitingClassStats": "",
  "color": "",
  "division": ""
}]

export interface ColumnConfiguration {
  key: StagedPlayerColumn;
  title: string;
  className: string;
  sticky: boolean;
  sortable: boolean;
  truncated: boolean;
  editable: boolean;
  nullable: boolean;
  hasTooltip: boolean;
  uppercase: boolean;
  centered: boolean;
  editType: EditType;
  multiSelectLabelWhenSelectedNone: string;
  multiSelectLabelWhenSelectedAll: string;
  renderContent: (stagedPlayer:StagedPlayer, validationRule?:ValidationRule) => any;
  validate: (
    stagedPlayer:StagedPlayer,
    validationRule?:ValidationRule,
    props?:StagedPlayerTableRowProps,
    expectedFormat?:string,
  ) => ValidationResult;
  expectedFormat: string;
  sort: (players:StagedPlayer[], order:Order) => StagedPlayer[];
  getDropdownItems: (properties:{ states:StateType[], hsCombineType:HsCombineType[] }) =>
    Array<{ content:string; value:string }>;
  getMultiSelectItems: () => Array<{ content:string; value:string }>;
  getMultiSelectSelectedValues: (columnValue:any) => string[];
  transformMultiSelectSelectedValues: (values:string[]) => any;
  getAutocompleteItems: (properties:{ divITeams:Team[], collegeTeams:Team[], nflTeams:Team[], teamsOnly: Team[] }) => Team[];
}

function getColumnConfiguration (columnName:StagedPlayerColumn, overrides:any = {}):ColumnConfiguration {
  return {
    key: columnName,
    // @ts-ignore
    title: COLUMN_TITLE[columnName],
    className: '',
    sticky: false,
    sortable: true,
    truncated: false,
    editable: true,
    uppercase: false,
    centered: false,
    hasTooltip: false,
    editType: EditType.TEXT,
    renderContent: (stagedPlayer:StagedPlayer, validationRule:ValidationRule) => {
      // @ts-ignore
      const columnValue = stagedPlayer.data[columnName];
      const isNumber = typeof columnValue === 'number';

      return (isNumber && validationRule) || (!isNumber && columnValue && validationRule)
        ? formatValue(columnValue, validationRule)
        : '';
    },
    validate: (stagedPlayer:StagedPlayer, validationRule:ValidationRule, props?:StagedPlayerTableRowProps, expectedFormat?:string):ValidationResult => {
      // there's no need to validate if a user can't change the value
      if (typeof overrides?.editable === 'boolean' && overrides.editable === false) {
        return { valid: true, error: '' } as ValidationResult;
      }

      const isTrackEvent = TRACK_EVENT_COLUMNS.includes(columnName);

      // @ts-ignore
      const columnValue = isTrackEvent ? (stagedPlayer.data[columnName]?.performance || null) : stagedPlayer.data[columnName];

      return validate(columnValue, validationRule, expectedFormat, props);
    },
    sort: (players:StagedPlayer[], order:Order) => {
      return sortPlayersData(players, order, (stagedPlayer:StagedPlayer) =>
        // @ts-ignore
        stagedPlayer.data[columnName]
      );
    },
    ...overrides,
  };
}

function formatValue (value:any, validationRule?:ValidationRule) {
  if (!validationRule) return value;

  switch (validationRule.type) {
    case ValidationRuleType.DATE:
      const isDatetime = !value.includes('/') && !datetimeToDate(value).includes('NaN');
      return isDatetime ? datetimeToDate(value) : value;
    case ValidationRuleType.SECONDS:
      return (typeof value === 'number' && value >= SECONDS_PER_MINUTE)
        ? secondsToMinutesSecondsMs(value)
        : value;
    default:
      return value;
  }
}

function validate (
  value:any,
  validationRule?:ValidationRule,
  expectedFormat?:string,
  props?: StagedPlayerTableRowProps
):ValidationResult {
  const defaultResult = { valid: true, error: '' };
  if (!value || !validationRule) return defaultResult;

  if (validationRule.type === ValidationRuleType.TEXT && validationRule?.enumValues?.length) {
    let valid = validationRule.enumValues.includes(value);
    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }
    return {
      valid,
      error: !valid ? `Must be of of the following values: ${validationRule.enumValues.join(', ')}` : '',
    };
  } else if (validationRule.type === ValidationRuleType.TEXT && validationRule.pattern) {
    let valid = new RegExp(validationRule.pattern, 'i').test(value);
    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }
    return {
      valid,
      error: !valid ? `Invalid input format${expectedFormat ? `. Expected: ${expectedFormat}` : ''}` : '',
    };
  } else if (validationRule.type === ValidationRuleType.INT) {
    const intStart = validationRule.intStart || 0;
    const intEnd = validationRule.intEnd || Infinity;
    let valid = /^-?\d+$/.test(String(value)) && value >= intStart && value <= intEnd;
    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }
    return {
      valid,
      error: !valid ? `Must be an integer in range ${intStart}-${intEnd}` : '',
    };
  } else if (validationRule.type === ValidationRuleType.FLOAT) {
    const floatStart = validationRule.floatStart || 0;
    const floatEnd = validationRule.floatEnd || Infinity;
    let valid = /^-?[0-9]\d*\.?\d*$/.test(String(value)) && value >= floatStart && value <= floatEnd;
    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }
    return {
      valid,
      error: !valid ? `Must be a number in range ${floatStart}-${floatEnd}` : '',
    };
  } else if (validationRule.type === ValidationRuleType.SECONDS) {
    const floatStart = validationRule.floatStart || 0;
    const floatEnd = validationRule.floatEnd || Infinity;

    const formattedValue = String(formatValue(value, validationRule));

    const hasMinutes = formattedValue.includes(':');
    const minutes = hasMinutes ? (Number(formattedValue.split(':')[0]) || 0) : 0;
    const seconds = hasMinutes ? (Number(formattedValue.split(':')[1]) || 0) : Number(value);
    const totalSeconds = minutes * SECONDS_PER_MINUTE + seconds;

    let valid = totalSeconds >= floatStart
      && totalSeconds <= floatEnd
      && seconds < SECONDS_PER_MINUTE
      && !String(value).endsWith(':')
      && !String(value).endsWith('.');

      if (validationRule.nullable) {
        if (Number(value) === -1) valid = true;
      }
    return {
      valid,
      error: !valid ? `Must be a value in range ${secondsToMinutesSecondsMs(floatStart)}-${secondsToMinutesSecondsMs(floatEnd)}` : '',
    };
  } else if (validationRule.type === ValidationRuleType.INCHES) {
    // a very tricky logic here with inches: the values are stored and displayed as an integer
    // where the last two digits specify inches, and the rest - feet (e.g. 7012 - 70' 12")
    // however, the range is specified in floatStart - floatEnd
    const INCHES_IN_FEET = 12;
    const inchesStart = validationRule.floatStart || 1;
    const inchesEnd = validationRule.floatEnd || Infinity;
    const formattedInchesStart = inchesToFeetInInchesAsSingleNumber(inchesStart);
    const formattedInchesEnd = inchesToFeetInInchesAsSingleNumber(inchesEnd);
    const isNumericValue = !isNaN(Number(value)) && !isNaN(parseFloat(value));
    const last2digitsExceedInchesInFeet = Number(String(value).slice(-2)) > INCHES_IN_FEET;

    let valid = isNumericValue
      && !last2digitsExceedInchesInFeet
      && !!value
      && value > 0
      && value >= formattedInchesStart
      && value <= formattedInchesEnd;

    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }
    return {
      valid,
      error: !valid
        ? last2digitsExceedInchesInFeet
          ? 'Last two digits specify inches (can\'t exceed 12)'
          : `Specify feet and inches as a single number in range ${formattedInchesStart}-${formattedInchesEnd}, where the last two digits mean inches`
        : '',
    };
  } else if (validationRule.type === ValidationRuleType.DATE) {
    // date format: mm/dd/yyyy
    const dateParts = String(value).split('/');
    const [month, day, year] = dateParts;
    let valid = typeof value === 'string'
      && dateParts.length === 3
      && !!month && (month.length === 1 || month.length === 2) && isNumericString(dateParts[0]) && Number(month) > 0 && Number(month) <= 12
      && !!day && (day.length === 1 || day.length === 2) && isNumericString(dateParts[1]) && Number(day) > 0 && Number(day) <= 31
      && !!year && year.length === 4 && isNumericString(dateParts[2]);

    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }

    return {
      valid,
      error: !valid ? `Must be a string in format mm/dd/yyyy` : '',
    };
  } else if (validationRule.type === ValidationRuleType.INT_LIST) {
    const intStart = validationRule.intStart || 0;
    const intEnd = validationRule.intEnd || Infinity;

    const isIntList = new RegExp('^\\d+(,\\s*\\d+)*$', 'i').test(String(value));
    const allNumbersInRange = String(value)
      .split(',')
      .map((year:string) => parseInt(year.trim(), 10))
      .every((year:number) => year >= intStart && year <= intEnd);
    let valid = isIntList && allNumbersInRange;

    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }

    return {
      valid,
      error: !valid ? `Must be a comma-separated list of years in range ${intStart}-${intEnd} (with or without spaces)` : '',
    };
  } else if (validationRule.type === ValidationRuleType.URL || validationRule.type === ValidationRuleType.LINK_URL) {
    let valid = /^(http|https):\/\/[^ "]+$/.test(String(value));

    if (validationRule.nullable) {
      if (Number(value) === -1) valid = true;
    }

    return {
      valid,
      error: !valid ? 'Invalid URL' : '',
    };
  } else if (validationRule.type === ValidationRuleType.HS_COMBINE_TYPE) {
    let valid = false;
    let hsCombType = props?.hsCombineTypes?.map(hs => hs.type?.toString()?.toLowerCase());
    const valueToLowerCase = value?.toString()?.toLowerCase();
    if (hsCombType?.includes(valueToLowerCase)) valid = true;
    return {
      valid,
      error: !valid ? 'Invalid Hs Combine types value' : '',
    };
  } else {
    return defaultResult;
  }
}

function getServerValidationError (serverErrors:StagedPlayerValidationError[], columnName:StagedPlayerColumn) {
  const validationError = (serverErrors || [])
    .find((rule:StagedPlayerValidationError) => {
      if (rule.key.includes('_date')) {
        // console.log('rule', rule.key);
        let preparedKey = rule.key.split('_');
        let newKey = preparedKey[0];
        // console.log('newKey', newKey);
        return newKey === columnName;
      }
      return rule.key === columnName
    });

  return {
    valid: !validationError?.message,
    error: validationError?.message || '',
  };
}

function isNumericString (value:any):boolean {
  if (typeof value !== 'string') return false;

  return !isNaN(Number(value)) && !isNaN(parseFloat(value));
}

export const TABLE_COLUMNS:ColumnConfiguration[] = COLUMNS_IN_ORDER
  .map((column:StagedPlayerColumn) => {
    switch (column) {
      case StagedPlayerColumn.STATUS:
        return getColumnConfiguration(StagedPlayerColumn.STATUS, {
          sticky: true,
          editable: false,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.isAlreadyPresent ? STATUS.UPDATED : STATUS.NEW;
          },
          sort: (players:StagedPlayer[], order:Order) => {
            return sortPlayersData(players, order, (stagedPlayer:StagedPlayer) =>
              String(stagedPlayer.isAlreadyPresent)
            );
          },
        });
      case StagedPlayerColumn.SLUG:
        return getColumnConfiguration(StagedPlayerColumn.SLUG, {
          sticky: true,
          editable: false,
          truncated: true,
          renderContent: (stagedPlayer:StagedPlayer) => stagedPlayer.slug || '',
          sort: (players:StagedPlayer[], order:Order) => {
            return sortPlayersData(players, order, (stagedPlayer:StagedPlayer) =>
              stagedPlayer.slug || ''
            );
          },
        });
      case StagedPlayerColumn.KEY_247:
        return getColumnConfiguration(StagedPlayerColumn.KEY_247);
      // case StagedPlayerColumn.UNIQUE_ID:
      //   return getColumnConfiguration(StagedPlayerColumn.UNIQUE_ID, {
      //     truncated: true,
      //     editable: false,
      //   });
      case StagedPlayerColumn.PFF_ID:
        return getColumnConfiguration(StagedPlayerColumn.PFF_ID);
      case StagedPlayerColumn.SSA_ID:
        return getColumnConfiguration(StagedPlayerColumn.SSA_ID);
      case StagedPlayerColumn.RYZER_ID:
        return getColumnConfiguration(StagedPlayerColumn.RYZER_ID, { truncated: true });
      case StagedPlayerColumn.LAST_EDITED:
        return getColumnConfiguration(StagedPlayerColumn.LAST_EDITED, {
          editable: false,
          renderContent: (stagedPlayer:StagedPlayer) => (stagedPlayer.lastPublishedDate || stagedPlayer.updatedAt)
            // @ts-ignore
            ? datetimeToDate(stagedPlayer.lastPublishedDate || stagedPlayer.updatedAt)
            : <NotAvailable />,
          sort: (players:StagedPlayer[], order:Order) => {
            return sortPlayersData(players, order, (stagedPlayer:StagedPlayer) =>
              String(stagedPlayer.lastPublishedDate || stagedPlayer.updatedAt)
            );
          },
        });
      case StagedPlayerColumn.ARCHIVED:
        return getColumnConfiguration(StagedPlayerColumn.ARCHIVED, {
          editable: false,
          renderContent: (stagedPlayer:StagedPlayer) => stagedPlayer.isArchived ? 'Yes' : 'No',
          sort: (players:StagedPlayer[], order:Order) => {
            return sortPlayersData(players, order, (stagedPlayer:StagedPlayer) =>
              String(stagedPlayer.isArchived)
            );
          },
        });
      case StagedPlayerColumn.PAI:
        return getColumnConfiguration(StagedPlayerColumn.PAI, {
          editable: false,
          hasTooltip: true,
          renderContent: (stagedPlayer:StagedPlayer) => {
            // @ts-ignore
            const score = stagedPlayer.data[StagedPlayerColumn.PAI];
            return typeof score === 'number'
              ? <Tooltip title={score.toFixed(2)}>
                  <div><Score type={SCORE_TYPE.PAI} value={score} /></div>
                </Tooltip>
              : '';
          }
        });
      case StagedPlayerColumn.COMBINE:
        return getColumnConfiguration(StagedPlayerColumn.COMBINE, {
          editable: false,
          hasTooltip: true,
          renderContent: (stagedPlayer:StagedPlayer) => {
            // @ts-ignore
            const score = stagedPlayer.data[StagedPlayerColumn.COMBINE];
            return typeof score === 'number'
              ? <Tooltip title={score.toFixed(2)}>
                  <div><Score type={SCORE_TYPE.COMBINE} value={score} /></div>
                </Tooltip>
              : '';
          }
        });
      case StagedPlayerColumn.PPI_SCORE:
        return getColumnConfiguration(StagedPlayerColumn.PPI_SCORE);
      case StagedPlayerColumn.PPI_DATE:
        return getColumnConfiguration(StagedPlayerColumn.PPI_DATE, {
          editType: EditType.EDIT_DATE,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.PPI_DATE] || null;
          },
        });
      case StagedPlayerColumn.HS_CLASS:
        return getColumnConfiguration(StagedPlayerColumn.HS_CLASS);
      case StagedPlayerColumn.LAST_NAME:
        return getColumnConfiguration(StagedPlayerColumn.LAST_NAME);
      case StagedPlayerColumn.FIRST_NAME:
        return getColumnConfiguration(StagedPlayerColumn.FIRST_NAME);
      case StagedPlayerColumn.HS_STATE:
        return getColumnConfiguration(StagedPlayerColumn.HS_STATE, {
          editType: EditType.DROPDOWN,
          getDropdownItems: ({ states }:{ states:StateType[] }) => {
            return (states || [])
              .map((state:StateType) => ({
                content: `${state.code} - ${state.name}`,
                value: state.code,
              }));
          },
        });
      case StagedPlayerColumn.HS_NAME:
        return getColumnConfiguration(StagedPlayerColumn.HS_NAME, {
          truncated: true,
        });
      case StagedPlayerColumn.HS_CITY:
        return getColumnConfiguration(StagedPlayerColumn.HS_CITY);
      case StagedPlayerColumn.HS_COUNTY:
        return getColumnConfiguration(StagedPlayerColumn.HS_COUNTY);
      case StagedPlayerColumn.HS_ZIP_CODE:
        return getColumnConfiguration(StagedPlayerColumn.HS_ZIP_CODE);
      case StagedPlayerColumn.HS_PRIMARY_POSITION:
        return getColumnConfiguration(StagedPlayerColumn.HS_PRIMARY_POSITION, {
          uppercase: true,
          validate: (stagedPlayer:StagedPlayer) => validatePrimaryPosition(
            stagedPlayer.data[StagedPlayerColumn.HS_PRIMARY_POSITION],
            stagedPlayer.data[StagedPlayerColumn.HS_POSITIONS],
          ),
        });
      case StagedPlayerColumn.HS_POSITIONS:
        return getColumnConfiguration(StagedPlayerColumn.HS_POSITIONS, {
          uppercase: true,
          validate: (stagedPlayer:StagedPlayer) => validatePositions(
            stagedPlayer.data[StagedPlayerColumn.HS_PRIMARY_POSITION],
            stagedPlayer.data[StagedPlayerColumn.HS_POSITIONS],
          ),
        });
      case StagedPlayerColumn.HS_HEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.HS_HEIGHT);
      case StagedPlayerColumn.HS_WEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.HS_WEIGHT);
      case StagedPlayerColumn.DOB:
        return getColumnConfiguration(StagedPlayerColumn.DOB);
      case StagedPlayerColumn.GPA:
        return getColumnConfiguration(StagedPlayerColumn.GPA);
      case StagedPlayerColumn.SAT:
        return getColumnConfiguration(StagedPlayerColumn.SAT);
      case StagedPlayerColumn.ACT:
        return getColumnConfiguration(StagedPlayerColumn.ACT);
      case StagedPlayerColumn.COMP_247:
        return getColumnConfiguration(StagedPlayerColumn.COMP_247);
      case StagedPlayerColumn.COMP_STAR:
        return getColumnConfiguration(StagedPlayerColumn.COMP_STAR, {
          editType: EditType.DROPDOWN,
        });
      case StagedPlayerColumn.STAR_247:
        return getColumnConfiguration(StagedPlayerColumn.STAR_247, {
          editType: EditType.DROPDOWN,
        });
      case StagedPlayerColumn.OFFER_COUNT:
        return getColumnConfiguration(StagedPlayerColumn.OFFER_COUNT);
      case StagedPlayerColumn.OFFER_SCHOOLS:
        return getColumnConfiguration(StagedPlayerColumn.OFFER_SCHOOLS, {
          truncated: true,
          validate: (
            stagedPlayer:StagedPlayer,
            validationRule:ValidationRule,
            { collegeTeams = [], nflTeams = [] },
          ) => {
            const offerSchools = stagedPlayer.data[StagedPlayerColumn.OFFER_SCHOOLS];
            const offerSchoolNames = (offerSchools || '')?.toString()
              .split(',')
              .map((teamName:string) => teamName.trim())
              .filter(Boolean);
            const allTeamsShortNames = [...collegeTeams, ...nflTeams].map((team:Team) => team.shortName);
            const invalidOfferTeams = offerSchoolNames.filter((offerSchoolName:string) => !allTeamsShortNames.includes(offerSchoolName));
            let valid = (!!offerSchools && !!offerSchools?.toString().trim())
              ? offerSchoolNames.length > 0 && invalidOfferTeams.length === 0
              : true;

            if (Number(offerSchools) < 0) valid = true;

            return {
              valid,
              error: !valid ? `Some schools do not exist: ${invalidOfferTeams.join(', ')}` : '',
            };
          },
        });
      case StagedPlayerColumn.COMMITTED_SCHOOL:
        return getColumnConfiguration(StagedPlayerColumn.COMMITTED_SCHOOL, {
          editType: EditType.AUTOCOMPLETE,
          // getAutocompleteItems: ({ divITeams }:{ divITeams:Team[] }) => divITeams,
          getAutocompleteItems: ({ teamsOnly }:{ teamsOnly:Team[] }) => {
            return ([...uncommitedDemoTeam, ...teamsOnly] || [])
          },
        });
      case StagedPlayerColumn.COMMITTED_DATE:
        return getColumnConfiguration(StagedPlayerColumn.COMMITTED_DATE);
      case StagedPlayerColumn.TF_URL:
        return getColumnConfiguration(StagedPlayerColumn.TF_URL, {
          truncated: true,
          editable: false,
        });
      case StagedPlayerColumn.PLAYER_PHOTO:
        return getColumnConfiguration(StagedPlayerColumn.PLAYER_PHOTO, {
          truncated: true,
        });
      case StagedPlayerColumn.URL_247:
        return getColumnConfiguration(StagedPlayerColumn.URL_247, {
          truncated: true,
        });
      case StagedPlayerColumn.RIVALS_URL:
        return getColumnConfiguration(StagedPlayerColumn.RIVALS_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.ON3_URL:
        return getColumnConfiguration(StagedPlayerColumn.ON3_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.HUDL_URL:
        return getColumnConfiguration(StagedPlayerColumn.HUDL_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.MAXPREPS_URL:
        return getColumnConfiguration(StagedPlayerColumn.MAXPREPS_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.TWITTER_URL:
        return getColumnConfiguration(StagedPlayerColumn.TWITTER_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.INSTAGRAM_URL:
        return getColumnConfiguration(StagedPlayerColumn.INSTAGRAM_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.ATHLETIC_URL:
        return getColumnConfiguration(StagedPlayerColumn.ATHLETIC_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.MILESPLIT_URL:
        return getColumnConfiguration(StagedPlayerColumn.MILESPLIT_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.DIRECT_ATHLETICS_URL:
        return getColumnConfiguration(StagedPlayerColumn.DIRECT_ATHLETICS_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.BASEBALL:
        return getColumnConfiguration(StagedPlayerColumn.BASEBALL, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.BASKETBALL:
        return getColumnConfiguration(StagedPlayerColumn.BASKETBALL, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.GOLF:
        return getColumnConfiguration(StagedPlayerColumn.GOLF, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.HOCKEY:
        return getColumnConfiguration(StagedPlayerColumn.HOCKEY, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.LACROSSE:
        return getColumnConfiguration(StagedPlayerColumn.LACROSSE, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.POWERLIFTING:
        return getColumnConfiguration(StagedPlayerColumn.POWERLIFTING, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.RUGBY:
        return getColumnConfiguration(StagedPlayerColumn.RUGBY, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.SOCCER:
        return getColumnConfiguration(StagedPlayerColumn.SOCCER, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.SWIMMING:
        return getColumnConfiguration(StagedPlayerColumn.SWIMMING, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.TENNIS:
        return getColumnConfiguration(StagedPlayerColumn.TENNIS, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.TRACK:
        return getColumnConfiguration(StagedPlayerColumn.TRACK, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.VOLLEYBALL:
        return getColumnConfiguration(StagedPlayerColumn.VOLLEYBALL, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.WRESTLING:
        return getColumnConfiguration(StagedPlayerColumn.WRESTLING, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.TRACK_55M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_55M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_55M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_55HH:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_55HH, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_55HH]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_60M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_60M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_60M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_60HH:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_60HH, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_60HH]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_100M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_100M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_100M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_110HH:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_110HH, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_110HH]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_200M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_200M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_200M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_300M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_300M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_300M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_300IH:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_300IH, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_300IH]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_400M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_400M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_400M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_400H:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_400H, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_400H]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_800M:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_800M, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_800M]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_400R:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_400R, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_400R]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_800R:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_800R, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_800R]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_1600R:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_1600R,{
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_1600R]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_3200R:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_3200R, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_3200R]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_SHOTPUT:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_SHOTPUT, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_SHOTPUT]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_DISCUS:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_DISCUS, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_DISCUS]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_JAVELIN:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_JAVELIN, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_JAVELIN]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_HIGH_JUMP:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_HIGH_JUMP, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_HIGH_JUMP]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_LONG_JUMP:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_LONG_JUMP, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_LONG_JUMP]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_TRIPLE_JUMP:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_TRIPLE_JUMP, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_TRIPLE_JUMP]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_POLE_VAULT:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_POLE_VAULT, {
          editType: EditType.EDIT_TRACK_EVENT,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.TRACK_POLE_VAULT]?.performance || null;
          },
        });
      case StagedPlayerColumn.TRACK_A_TIER:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_A_TIER, {editable: false});
      case StagedPlayerColumn.TRACK_B_TIER:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_B_TIER, {editable: false});
      case StagedPlayerColumn.TRACK_C_TIER:
        return getColumnConfiguration(StagedPlayerColumn.TRACK_C_TIER, {editable: false});
      case StagedPlayerColumn.HS_COMBINE_EVENT:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_EVENT, {
          editType: EditType.DROPDOWN,
          nullable: true,
          getDropdownItems: ({ hsCombineType }:{ hsCombineType:HsCombineType[] }) => {
            return (hsCombineType || [])
              .map((hsCombineType:HsCombineType) => ({
                content: `${hsCombineType.type}`,
                value: `${hsCombineType.type}`,
              }));
          },
        });
      case StagedPlayerColumn.HS_COMBINE_LOCATION:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_LOCATION);
      case StagedPlayerColumn.HS_COMBINE_DATE:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_DATE);
      case StagedPlayerColumn.HS_COMBINE_HEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_HEIGHT);
      case StagedPlayerColumn.HS_COMBINE_WEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_WEIGHT);
      case StagedPlayerColumn.HS_COMBINE_WINGSPAN:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_WINGSPAN);
      case StagedPlayerColumn.HS_COMBINE_HAND:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_HAND);
      case StagedPlayerColumn.HS_COMBINE_ARM:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_ARM);
      case StagedPlayerColumn.HS_COMBINE_MPH:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_MPH);
      case StagedPlayerColumn.HS_COMBINE_10_SPLIT:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_10_SPLIT);
      case StagedPlayerColumn.HS_COMBINE_40M_DASH:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_40M_DASH);
      case StagedPlayerColumn.HS_COMBINE_SHUTTLE:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_SHUTTLE);
      case StagedPlayerColumn.HS_COMBINE_3_CONE:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_3_CONE);
      case StagedPlayerColumn.HS_COMBINE_VERTICAL:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_VERTICAL);
      case StagedPlayerColumn.HS_COMBINE_BROAD:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_BROAD);
      case StagedPlayerColumn.HS_COMBINE_POWER_TOSS:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_POWER_TOSS);
      case StagedPlayerColumn.HS_COMBINE_RATING:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_RATING);
      case StagedPlayerColumn.ABOUT:
        return getColumnConfiguration(StagedPlayerColumn.ABOUT, {
          truncated: true,
        });
      case StagedPlayerColumn.PREP:
        return getColumnConfiguration(StagedPlayerColumn.PREP);
      case StagedPlayerColumn.PREP_NAME:
        return getColumnConfiguration(StagedPlayerColumn.PREP_NAME);
      case StagedPlayerColumn.TRANSFER_STATUS:
        return getColumnConfiguration(StagedPlayerColumn.TRANSFER_STATUS, {
          editType: EditType.DROPDOWN,
          renderContent: (stagedPlayer:StagedPlayer) => (stagedPlayer.data[StagedPlayerColumn.TRANSFER_STATUS] || '').toLowerCase(),
        });
      case StagedPlayerColumn.TRANSFER_YEAR:
        return getColumnConfiguration(StagedPlayerColumn.TRANSFER_YEAR, {
          expectedFormat: 'yy-yy',
        });
      case StagedPlayerColumn.NCAA_ID:
        return getColumnConfiguration(StagedPlayerColumn.NCAA_ID);
      case StagedPlayerColumn.COLLEGE_SCHOLARSHIP:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_SCHOLARSHIP, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.COLLEGE_COMMITTED_TO:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_COMMITTED_TO, {
          editType: EditType.AUTOCOMPLETE,
          getAutocompleteItems: ({ teamsOnly }:{ teamsOnly:Team[] }) => teamsOnly,
        });
      case StagedPlayerColumn.COLLEGE_ACTIVE:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_ACTIVE, {
          editType: EditType.CHECKBOX,
          centered: true,
        });
      case StagedPlayerColumn.COLLEGE_URL:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.PFF_URL:
        return getColumnConfiguration(StagedPlayerColumn.PFF_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.SSA_URL:
        return getColumnConfiguration(StagedPlayerColumn.SSA_URL, {
          truncated: true,
        });
      case StagedPlayerColumn.COLLEGE_NAME:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_NAME, {
          editType: EditType.AUTOCOMPLETE,
          nullable: true,
          getAutocompleteItems: ({ collegeTeams }:{ collegeTeams:Team[] }) => collegeTeams,
        });
      case StagedPlayerColumn.COLLEGE_RECRUITING_CLASS:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_RECRUITING_CLASS);
      case StagedPlayerColumn.COLLEGE_PRIMARY_POSITION:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_PRIMARY_POSITION, {
          uppercase: true,
          validate: (stagedPlayer:StagedPlayer) => validatePrimaryPosition(
            stagedPlayer.data[StagedPlayerColumn.COLLEGE_PRIMARY_POSITION],
            stagedPlayer.data[StagedPlayerColumn.COLLEGE_POSITIONS],
          ),
        });
      case StagedPlayerColumn.COLLEGE_POSITIONS:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_POSITIONS, {
          uppercase: true,
          validate: (stagedPlayer:StagedPlayer) => validatePositions(
            stagedPlayer.data[StagedPlayerColumn.COLLEGE_PRIMARY_POSITION],
            stagedPlayer.data[StagedPlayerColumn.COLLEGE_POSITIONS],
          ),
        });
      case StagedPlayerColumn.COLLEGE_HEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_HEIGHT);
      case StagedPlayerColumn.COLLEGE_WEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_WEIGHT);
      case StagedPlayerColumn.COLLEGE_JERSEY_NUMBER:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_JERSEY_NUMBER);
      case StagedPlayerColumn.ALL_CONFERENCE:
        return getColumnConfiguration(StagedPlayerColumn.ALL_CONFERENCE);
      case StagedPlayerColumn.ALL_AMERICAN:
        return getColumnConfiguration(StagedPlayerColumn.ALL_AMERICAN);
      case StagedPlayerColumn.WATCH_LIST_EVENT:
        return getColumnConfiguration(StagedPlayerColumn.WATCH_LIST_EVENT, {
          editType: EditType.MULTISELECT,
          multiSelectLabelWhenSelectedNone: 'Select Events',
          getMultiSelectSelectedValues: (columnValue:any) => String(columnValue)
            .split(',')
            .map((event:string) => event.trim())
            .filter(Boolean),
          transformMultiSelectSelectedValues: (values:string[]) => values.join(', '),
        });
      case StagedPlayerColumn.WATCH_LIST_YEAR:
        return getColumnConfiguration(StagedPlayerColumn.WATCH_LIST_YEAR);
      case StagedPlayerColumn.PRO_COMBINE:
        return getColumnConfiguration(StagedPlayerColumn.PRO_COMBINE, {
          editType: EditType.DROPDOWN,
          nullable: true,
        });
      case StagedPlayerColumn.NFL_COMBINE_YEAR:
        return getColumnConfiguration(StagedPlayerColumn.NFL_COMBINE_YEAR);
      case StagedPlayerColumn.NFL_PRIMARY_POSITION:
        return getColumnConfiguration(StagedPlayerColumn.NFL_PRIMARY_POSITION, {
          uppercase: true,
          validate: (stagedPlayer:StagedPlayer) => validatePrimaryPosition(
            stagedPlayer.data[StagedPlayerColumn.NFL_PRIMARY_POSITION],
            stagedPlayer.data[StagedPlayerColumn.NFL_POSITIONS],
          ),
        });
      case StagedPlayerColumn.NFL_COMBINE_SCORE:
        return getColumnConfiguration(StagedPlayerColumn.NFL_COMBINE_SCORE);
      case StagedPlayerColumn.NFL_POSITIONS:
        return getColumnConfiguration(StagedPlayerColumn.NFL_POSITIONS, {
          uppercase: true,
          validate: (stagedPlayer:StagedPlayer) => validatePositions(
            stagedPlayer.data[StagedPlayerColumn.NFL_PRIMARY_POSITION],
            stagedPlayer.data[StagedPlayerColumn.NFL_POSITIONS],
          ),
        });
      case StagedPlayerColumn.NFL_HEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.NFL_HEIGHT);
      case StagedPlayerColumn.NFL_WEIGHT:
        return getColumnConfiguration(StagedPlayerColumn.NFL_WEIGHT);
      case StagedPlayerColumn.NFL_HAND:
        return getColumnConfiguration(StagedPlayerColumn.NFL_HAND);
      case StagedPlayerColumn.NFL_WINGSPAN:
        return getColumnConfiguration(StagedPlayerColumn.NFL_WINGSPAN);
      case StagedPlayerColumn.NFL_ARM:
        return getColumnConfiguration(StagedPlayerColumn.NFL_ARM);
      case StagedPlayerColumn.NFL_40:
        return getColumnConfiguration(StagedPlayerColumn.NFL_40);
      case StagedPlayerColumn.NFL_10_SPLIT:
        return getColumnConfiguration(StagedPlayerColumn.NFL_10_SPLIT);
      case StagedPlayerColumn.NFL_20_SPLIT:
        return getColumnConfiguration(StagedPlayerColumn.NFL_20_SPLIT);
      case StagedPlayerColumn.NFL_MPH:
        return getColumnConfiguration(StagedPlayerColumn.NFL_MPH);
      case StagedPlayerColumn.NFL_SHUTTLE:
        return getColumnConfiguration(StagedPlayerColumn.NFL_SHUTTLE);
      case StagedPlayerColumn.NFL_3_CONE:
        return getColumnConfiguration(StagedPlayerColumn.NFL_3_CONE);
      case StagedPlayerColumn.NFL_VERTICAL:
        return getColumnConfiguration(StagedPlayerColumn.NFL_VERTICAL);
      case StagedPlayerColumn.NFL_BROAD:
        return getColumnConfiguration(StagedPlayerColumn.NFL_BROAD);
      case StagedPlayerColumn.NFL_BENCH:
        return getColumnConfiguration(StagedPlayerColumn.NFL_BENCH);
      case StagedPlayerColumn.NFL_TEAM_NAME:
        return getColumnConfiguration(StagedPlayerColumn.NFL_TEAM_NAME, {
          editType: EditType.AUTOCOMPLETE,
          nullable: true,
          getAutocompleteItems: ({ nflTeams }:{ nflTeams:Team[] }) => nflTeams,
        });
      case StagedPlayerColumn.NFL_DRAFT_YEAR:
        return getColumnConfiguration(StagedPlayerColumn.NFL_DRAFT_YEAR);
      case StagedPlayerColumn.NFL_DRAFT_ROUND:
        return getColumnConfiguration(StagedPlayerColumn.NFL_DRAFT_ROUND, {
          sort: (players:StagedPlayer[], order:Order) => {
            return sortPlayersData(players, order, (stagedPlayer:StagedPlayer) =>
              String(stagedPlayer.data[StagedPlayerColumn.NFL_DRAFT_ROUND]),
              true
            );
          },
        });
      case StagedPlayerColumn.OVERALL_PICK:
        return getColumnConfiguration(StagedPlayerColumn.OVERALL_PICK);
      case StagedPlayerColumn.PRO_BOWL:
        return getColumnConfiguration(StagedPlayerColumn.PRO_BOWL, {
          truncated: true,
        });
      case StagedPlayerColumn.ALL_PRO:
        return getColumnConfiguration(StagedPlayerColumn.ALL_PRO, {
          truncated: true,
        });
      // New columns added
      case StagedPlayerColumn.OURLADS_ID:
        return getColumnConfiguration(StagedPlayerColumn.OURLADS_ID);
      case StagedPlayerColumn.RYZER_URL:
        return getColumnConfiguration(StagedPlayerColumn.RYZER_URL);
      case StagedPlayerColumn.MISC_URL:
        return getColumnConfiguration(StagedPlayerColumn.MISC_URL);
      case StagedPlayerColumn.SPEED_TAG:
        return getColumnConfiguration(StagedPlayerColumn.SPEED_TAG, {
          editable: false,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.SPEED_TAG] || 0;
          },
        });
      case StagedPlayerColumn.POWER_TAG:
        return getColumnConfiguration(StagedPlayerColumn.POWER_TAG, {
          editable: false,
          renderContent: (stagedPlayer:StagedPlayer) => {
            return stagedPlayer.data[StagedPlayerColumn.POWER_TAG] || 0;
          },
        });
      case StagedPlayerColumn.DISCOVERY:
        return getColumnConfiguration(StagedPlayerColumn.DISCOVERY);
      case StagedPlayerColumn.HS_COMBINE_TEAM_CAMP:
        return getColumnConfiguration(StagedPlayerColumn.HS_COMBINE_TEAM_CAMP, {
          editType: EditType.AUTOCOMPLETE,
          getAutocompleteItems: ({ collegeTeams }:{ collegeTeams:Team[] }) => collegeTeams,
        });
      case StagedPlayerColumn.PHONE:
        return getColumnConfiguration(StagedPlayerColumn.PHONE, { truncated: true });
      case StagedPlayerColumn.EMAIL:
        return getColumnConfiguration(StagedPlayerColumn.EMAIL);
      case StagedPlayerColumn.ADDRESS:
        return getColumnConfiguration(StagedPlayerColumn.ADDRESS, { truncated: true });
      case StagedPlayerColumn.CITY_STATE_ZIP:
        return getColumnConfiguration(StagedPlayerColumn.CITY_STATE_ZIP);
      case StagedPlayerColumn.PARENT_NAME:
        return getColumnConfiguration(StagedPlayerColumn.PARENT_NAME);
      case StagedPlayerColumn.PARENT_CONTACT:
        return getColumnConfiguration(StagedPlayerColumn.PARENT_CONTACT);
      case StagedPlayerColumn.HEAD_COACH:
        return getColumnConfiguration(StagedPlayerColumn.HEAD_COACH);
      case StagedPlayerColumn.HEAD_COACH_CONTACT:
        return getColumnConfiguration(StagedPlayerColumn.HEAD_COACH_CONTACT);
      case StagedPlayerColumn.HS_FILM_URL:
        return getColumnConfiguration(StagedPlayerColumn.HS_FILM_URL);
      case StagedPlayerColumn.HS_MPH:
        return getColumnConfiguration(StagedPlayerColumn.HS_MPH);
      case StagedPlayerColumn.HS_MPH_PERCENTAGE:
        return getColumnConfiguration(StagedPlayerColumn.HS_MPH_PERCENTAGE);
      case StagedPlayerColumn.HS_TIME_TO_MAX_SPEED:
        return getColumnConfiguration(StagedPlayerColumn.HS_TIME_TO_MAX_SPEED);
      case StagedPlayerColumn.HS_DISTANCE_TRAVELLED:
        return getColumnConfiguration(StagedPlayerColumn.HS_DISTANCE_TRAVELLED);
      case StagedPlayerColumn.COLLEGE_MPH:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_MPH);
      case StagedPlayerColumn.COLLEGE_MPH_PERCENTAGE:
        return getColumnConfiguration(StagedPlayerColumn.COLLEGE_MPH_PERCENTAGE);
      case StagedPlayerColumn.DEPTH_CHART:
        return getColumnConfiguration(StagedPlayerColumn.DEPTH_CHART);
      default:
        return null;
    }
  })
  .filter(Boolean) as ColumnConfiguration[];

function sortPlayersData (
  players:StagedPlayer[],
  order:Order,
  getValue: (player:StagedPlayer) => string | number,
  isDraftRound?: boolean,
):StagedPlayer[] {
  return players.sort((first:StagedPlayer, second:StagedPlayer) => {
    const value1 = getValue(first);
    const value2 = getValue(second);

    if (isDraftRound) {
      return sortDraftRound(value1, value2, order);
    }

    let result = 0;
    if (value1 < value2) {
      result = -1;
    } else if (value1 > value2) {
      result = 1;
    }

    return result * (order === Order.desc ? -1 : 1);
  });
}

const useStyles = makeStyles((theme: Theme) => ({
  stagedPlayerTableRow: {
    background: COLOR_BACKGROUND_LIGHT,

    '&:nth-of-type(2n)': {
      background: COLOR_WHITE,
    }
  },
  highlightedPlayer: {
    '& $checkBoxCell': {
      background: COLOR_LIGHT_ORANGE,
    },
  },

  tableCell: {
    padding: theme.spacing(1.25, 2.5),
    fontSize: theme.typography.pxToRem(16),
    border: 0,
  },

  invalid: {
    backgroundColor: COLOR_LIGHT_RED,
    border: `1px solid ${COLOR_RED}`,
    cursor: 'help',
    boxSizing: 'border-box',
  },
  edited: {
    padding: 0,
  },
  truncated: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'help',
  },
  immutable: {
    cursor: 'not-allowed',
  },
  withTooltip: {
    cursor: 'help',
  },
  centered: {
    textAlign: 'center',
  },

  checkmark: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: '0 auto',
    color: COLOR_MIDDLE_GRAY,
  },
  checkmarkChecked: {
    color: COLOR_GREEN,
  },

  checkBoxCell: {
    position: 'relative',
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    padding: '5px 10px',
  },

  input: {
    width: '100%',
  },
  inputField: {
    padding: theme.spacing(2, 1),
  },

  teamAutoComplete: {
    width: 240,
  },

  dropdown: {
    width: '100%',
  },

  multiSelect: {
    width: '100%',
  },
  multiSelectSelector: {},
  multiSelectSelectorRoot: {},

  editTrackEventPopover: {
    width: '100%',
  },
  halfInput: {
    marginLeft: theme.spacing(1),

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}), { name: StagedPlayerTableRow.name });

export default function StagedPlayerTableRow (props:StagedPlayerTableRowProps) {
  const {
    player,
    validationRules = [],
    states = [],
    divITeams = [],
    collegeTeams = [],
    nflTeams = [],
    hsCombineTypes = [],
    teamsOnly = [],
    editedPlayer,
    editedColumn,
    checked = false,
    onSelectPlayer,
    onEditColumn,
  } = props;

  const classes = useStyles();
  const location = useLocation();

  const [inputWidth, setInputWidth] = useState<number>();
  const [serverValidationFailedColumnNames, setServerValidationFailedColumnNames] = useState<StagedPlayerColumn[]>([]);
  const [date, setDate] = React.useState<Date | null>(null);

  useEffect(() => {
    if (player?.validationErrors?.length) {
      setServerValidationFailedColumnNames(
          player.validationErrors.map((rule:StagedPlayerValidationError) => {
          if (rule.key.includes('_date')) {
            let preparedKey = rule.key.split('_');
            let newKey = preparedKey[0];
            return newKey as StagedPlayerColumn
          }
          return rule.key as StagedPlayerColumn
        })
      );
    }
  }, [player?.validationErrors?.length]);

  function onStartEditing (player:StagedPlayer, key:StagedPlayerColumn) {
    return (event:any) => {
      setInputWidth(event.target.offsetWidth);

      onEditColumn(player, key);

      setServerValidationFailedColumnNames(
        serverValidationFailedColumnNames.filter((columnName:StagedPlayerColumn) => columnName !== key)
      );
    };
  }

  function onStopEditing () {
    onEditColumn(undefined, undefined);
  }

  const highlightedPlayerSlug = location.hash.replace('#scrollto-', '');

  return (
    <TableRow
      id={`scrollto-${player.slug}`}
      className={clsx(
        classes.stagedPlayerTableRow,
        (highlightedPlayerSlug && highlightedPlayerSlug === player.slug) && classes.highlightedPlayer,
      )}
    >
      <TableCell className={clsx(classes.tableCell, classes.checkBoxCell)}>
        <Checkbox
          checked={checked}
          onChange={onSelectPlayer(player)}
        />
      </TableCell>

      {(TABLE_COLUMNS.filter(Boolean) as ColumnConfiguration[])
        .map((tableColumn:ColumnConfiguration) => {
          const {
            key,
            className,
            truncated,
            editable,
            nullable,
            hasTooltip,
            uppercase,
            centered,
            editType,
            renderContent,
            validate,
            expectedFormat,
            getDropdownItems,
            getMultiSelectItems,
            getAutocompleteItems,
            multiSelectLabelWhenSelectedNone,
            multiSelectLabelWhenSelectedAll,
            getMultiSelectSelectedValues,
            transformMultiSelectSelectedValues,
          } = tableColumn;
          const validationRule = validationRules.find((rule:ValidationRule) => rule.key === key);
          const hasServerErrors = serverValidationFailedColumnNames.includes(key);

          const { valid, error } = hasServerErrors
            ? getServerValidationError(player.validationErrors, key)
            : validate(player, validationRule, props, expectedFormat);
          
          const isEdited = editedColumn && player && editedPlayer && player.id === editedPlayer.id && key === editedColumn;
          // @ts-ignore
          const columnValue:any = player?.data && player.data[key];
          const editedValue:any = typeof columnValue === 'number'
            ? isNaN(columnValue) ? '' : columnValue
            : columnValue ? String(columnValue) : '';

          const validationRuleEnumValues = validationRule?.enumValues?.length
            ? validationRule.enumValues.map((enumValue:any) => ({
                content: String(enumValue),
                value: String(enumValue),
              }))
            : [];

          let dropdownItems = getDropdownItems
            ? key === "hsCombineType" ? getDropdownItems({ states: [], hsCombineType: hsCombineTypes }) : getDropdownItems({ states, hsCombineType: [] })
            : validationRuleEnumValues;
          if (nullable) {
            dropdownItems.unshift({ content: 'None', value: 'null' });
          }

          const multiSelectItems = getMultiSelectItems
            ? getMultiSelectItems()
            : validationRuleEnumValues;

          const autocompleteItems:Team[] = getAutocompleteItems
            ? getAutocompleteItems({ divITeams, collegeTeams, nflTeams, teamsOnly })
            : [];

          const cellContent = renderContent(player, validationRule);

          const maxDate = formatDate(new Date());
          const today = new Date();
          const minDate = formatDate(new Date(today.setFullYear(today.getFullYear() - 15)));

          return (
            <TableCell
              key={key}
              className={clsx(
                classes.tableCell,
                !valid && classes.invalid,
                isEdited && classes.edited,
                !editable && classes.immutable,
                hasTooltip && classes.withTooltip,
                centered && classes.centered,
                // @ts-ignore
                className ? classes[className] : '',
              )}
              style={isEdited ? { minWidth: `${inputWidth}px` } : undefined}
              onClick={editable && !isEdited ? onStartEditing(player, key) : undefined}
            >
              {(!isEdited && (!valid || truncated)) && (
                <Tooltip title={error || cellContent}>
                  <div className={clsx(truncated && classes.truncated)}>
                    {cellContent || (truncated ? '' : <NotAvailable />)}
                  </div>
                </Tooltip>
              )}

              {(!isEdited && editType === EditType.CHECKBOX) && (
                columnValue !== null && columnValue !== undefined
                  ? <CheckIcon
                      className={clsx(
                        classes.checkmark,
                        String(columnValue) === '1' && classes.checkmarkChecked,
                      )}
                    />
                  : <NotAvailable />
              )}

              {(!isEdited && editType !== EditType.CHECKBOX && (valid && (!truncated || !cellContent))) && (
                typeof cellContent === 'number'
                  ? String(cellContent)
                  : cellContent || <NotAvailable />
              )}

              {(isEdited && editType === EditType.TEXT) && (
                <Input
                  className={classes.input}
                  inputFieldClassName={classes.inputField}
                  value={formatValue(editedValue, validationRule)}
                  onChange={(value:string) => {
                    onEditColumn(
                      player,
                      key,
                      uppercase ? value.toUpperCase() : value,
                    );
                  }}
                  onBlur={onStopEditing}
                  autoFocus
                />
              )}

              {(isEdited && editType === EditType.AUTOCOMPLETE) && (
                <TeamAutoComplete
                  className={classes.teamAutoComplete}
                  label='Select Team'
                  nullable={nullable || false}
                  teams={autocompleteItems}
                  selectedTeam={autocompleteItems.find((team:Team) => team?.shortName === editedValue) || null}
                  onSelect={(team:Team|null) => {
                    onEditColumn(
                      player,
                      key,
                      team === null ? null : team.shortName,
                    );
                  }}
                  onBlur={onStopEditing}
                />
              )}

              {(isEdited && editType === EditType.DROPDOWN) && (
                <DropDown
                  className={classes.dropdown}
                  items={dropdownItems}
                  value={(dropdownItems.find(item => item.value === String(editedValue)) || {}).value || (nullable ? 'null' : '')}
                  onChange={(value:string) => {
                    onEditColumn(
                      player,
                      key,
                      value === 'null' ? null : value,
                    );
                  }}
                  onBlur={onStopEditing}
                />
              )}

              {(isEdited && editType === EditType.MULTISELECT) && (
                <MultiSelect
                  className={classes.multiSelect}
                  selectorClassName={classes.multiSelectSelector}
                  selectorRootClassName={classes.multiSelectSelectorRoot}
                  labelWhenSelectedNone={multiSelectLabelWhenSelectedNone}
                  labelWhenSelectedAll={multiSelectLabelWhenSelectedAll}
                  items={multiSelectItems}
                  values={getMultiSelectSelectedValues(editedValue)}
                  onChange={(values:string[]) => onEditColumn(player, key, transformMultiSelectSelectedValues(values))}
                  onBlur={onStopEditing}
                />
              )}

              {(isEdited && editType === EditType.CHECKBOX) && (
                <Checkbox
                  checked={String(editedValue) === '1'}
                  onChange={(value:boolean) => onEditColumn(
                    player,
                    key,
                    value ? '1' : '0',
                  )}
                />
              )}

              {(isEdited && editType === EditType.EDIT_TRACK_EVENT) && (
                <EditTrackEventPopover
                  className={classes.editTrackEventPopover}
                  trackEvent={columnValue}
                  onUpdate={(trackEvent:StagedPlayerTrackEvent | null) => {
                    onEditColumn(
                      player,
                      key,
                      trackEvent,
                    );
                    onStopEditing();
                  }}
                />
              )}

              {(isEdited && editType === EditType.EDIT_DATE) && (
                <DatePicker
                  className={clsx(classes.halfInput, classes.input)}
                  label='Date'
                  value={player?.data?.ppiDate?.toString() || null}
                  onChange={(date:Date | null) => {
                    setDate(date);
                    onEditColumn(
                      player,
                      key,
                      formatDate(date)?.toString(),
                      );
                    }}
                  onBlur={onStopEditing}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}
            </TableCell>
          );
        })}
    </TableRow>
  );
}
