import * as clientStorage from './client-storage';
import { StorageKey } from './client-storage';

export enum XLSExportType {
  USERS = 'users',
  PROGRAMS = 'programs',
  PLAYERS = 'players',
  DEPTH_CHART = 'depth-charts',
}

export enum XLSExportPage {
  SEARCH = 'search',
  TEAM = 'team',
}

export const ERROR_MESSAGE_CHECK_EMAIL = 'check email';

export default function exportXLSReport (
  exportType:XLSExportType,
  exportParameters:object,
  page?:XLSExportPage,
):Promise<any> {
  const body:any = { ...exportParameters };
  if (page) body[page] = true;

  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${exportType}/export/xls`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      if (res.ok) {
        const needToCheckEMail = !res.headers.get('content-type');
        if (needToCheckEMail) {
          throw new Error(ERROR_MESSAGE_CHECK_EMAIL);
        } else {
          return {
            fileName: (res.headers.get('Content-Disposition') || '').split('filename=')[1] || `${exportType}.xlsx`,
            fileBlob: await res.blob(),
          };
        }
      } else {
        return res.text()
          .then((errorMessage:string) => {
            throw new Error(errorMessage);
          });
      }
    })
    .then(({ fileName, fileBlob }) => downloadAndSaveXLSReport(fileName, fileBlob));
}

export function downloadAndSaveXLSReport (fileName:string, fileContent?:Blob, fileUrl?: string) {
  const url = !fileUrl ? window.URL.createObjectURL(fileContent) : fileUrl;
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
}

export function uploadAndPostXlsFile (file:any) {
  let body = new FormData();
  body.append('file', file, file.name);

  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/import-requests/upload`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
    body,
  })
    .then(res => res.ok);
}

export function uploadAndPostDepthChartXLS (file:any) {
  let body = new FormData();
  body.append('file', file, file.name);

  return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/import-requests/upload-ourlads`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${clientStorage.get(StorageKey.TOKEN)}`,
    },
    body,
  })
    .then(res => res.ok);
}