import { StorageKey } from './client-storage';
import * as clientStorage from './client-storage';

export enum CacheKey {
  ALL_STATES = StorageKey.ALL_STATES,
  COLLEGE_TEAMS = StorageKey.COLLEGE_TEAMS,
  JUCO_TEAMS = StorageKey.JUCO_TEAMS,
  CONFIGURATIONS = StorageKey.CONFIGURATIONS,
  DIV_I_TEAMS = StorageKey.DIV_I_TEAMS,
  NFL_TEAMS = StorageKey.NFL_TEAMS,
  USER = StorageKey.USER,
  CONFERENCES_FBS = StorageKey.CONFERENCES_FBS,
  CONFERENCES_FCS = StorageKey.CONFERENCES_FCS,
}

const HOUR = 60 * 60 * 1000; // ms

export enum CacheExpiration {
  SHORT = HOUR , // ms
  MEDIUM = 6 * HOUR,
  LONG = 12 * HOUR,
}

const EXPIRATION_DATE_KEY = {
  [CacheKey.ALL_STATES]: StorageKey.ALL_STATES_EXPIRATION_DATE,
  [CacheKey.COLLEGE_TEAMS]: StorageKey.COLLEGE_TEAMS_EXPIRATION_DATE,
  [CacheKey.CONFIGURATIONS]: StorageKey.CONFIGURATIONS_EXPIRATION_DATE,
  [CacheKey.DIV_I_TEAMS]: StorageKey.DIV_I_TEAMS_EXPIRATION_DATE,
  [CacheKey.NFL_TEAMS]: StorageKey.NFL_TEAMS_EXPIRATION_DATE,
  [CacheKey.USER]: StorageKey.USER_EXPIRATION_DATE,
  [CacheKey.CONFERENCES_FBS]: StorageKey.CONFERENCES_FBS_EXPIRATION_DATE,
  [CacheKey.CONFERENCES_FCS]: StorageKey.CONFERENCES_FCS_EXPIRATION_DATE,
};

export function cache (key:CacheKey, data:any, expiration:CacheExpiration) {
  clientStorage.save(key as any, data);

  const expirationDate = +(new Date()) + expiration; // ms
  clientStorage.save(EXPIRATION_DATE_KEY[key], expirationDate);
}

export function retrieve (key:CacheKey):any | null {
  const currentDate = +(new Date()); // ms
  const expirationDate = clientStorage.get(EXPIRATION_DATE_KEY[key]);

  if (!expirationDate || currentDate >= Number(expirationDate)) {
    return null;
  }

  return clientStorage.get(key as any);
}
