import {
  SET_FBS_CONFERENCES_LOADING,
  SET_FBS_CONFERENCES,
  SET_FCS_CONFERENCES_LOADING,
  SET_FCS_CONFERENCES,
} from '../actions/conferences';
import Conference from '../../types/Conference';

export interface ConferencesState {
  fbsConferences: Conference[];
  fbsConferencesLoading: boolean;
  fcsConferences: Conference[];
  fcsConferencesLoading: boolean;
}

const INITIAL_STATE:ConferencesState = {
  fbsConferences: [],
  fbsConferencesLoading: false,
  fcsConferences: [],
  fcsConferencesLoading: false,
};

export function getConferencesInitialState () {
  return INITIAL_STATE;
}

export default function conferencesReducer (state = INITIAL_STATE, action?:any) {
  switch (action.type) {
    case SET_FBS_CONFERENCES_LOADING:
      return {
        ...state,
        fbsConferencesLoading: action.fbsConferencesLoading,
      };
    case SET_FBS_CONFERENCES:
      return {
        ...state,
        fbsConferences: action.fbsConferences,
      };
    case SET_FCS_CONFERENCES_LOADING:
      return {
        ...state,
        fcsConferencesLoading: action.fcsConferencesLoading,
      };
    case SET_FCS_CONFERENCES:
      return {
        ...state,
        fcsConferences: action.fcsConferences,
      };
    default:
      return state;
  }
};
