import Conference from '../../types/Conference';

export const SET_FBS_CONFERENCES_LOADING = 'conferences/SET_FBS_CONFERENCES_LOADING';
export const SET_FBS_CONFERENCES = 'conferences/SET_FBS_CONFERENCES';
export const SET_FCS_CONFERENCES_LOADING = 'conferences/SET_FCS_CONFERENCES_LOADING';
export const SET_FCS_CONFERENCES = 'conferences/SET_FCS_CONFERENCES';

export function setFBSConferencesLoading (fbsConferencesLoading:boolean) {
  return {
    type: SET_FBS_CONFERENCES_LOADING,
    fbsConferencesLoading,
  };
}

export function setFBSConferences (fbsConferences:Conference[]) {
  return {
    type: SET_FBS_CONFERENCES,
    fbsConferences,
  };
}

export function setFCSConferencesLoading (fcsConferencesLoading:boolean) {
  return {
    type: SET_FCS_CONFERENCES_LOADING,
    fcsConferencesLoading,
  };
}

export function setFCSConferences (fcsConferences:Conference[]) {
  return {
    type: SET_FCS_CONFERENCES,
    fcsConferences,
  };
}
